import React from 'react';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditUserModal from 'components/setup/EditUserModal';

//-----------------------------------------------------------------

class SetupUsers extends React.Component {

    constructor(props) {
        super(props);

        this.editUserModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.props.fns.setTitle('Users & Sweeps setup');
        this.load();
    }

    async load() {
        const users = await API.call('user/list');
        this.setState({
            isLoading: false,
            users
        });
    }

    async open(id) {
        await this.editUserModalRef.current.open({
            id
        });
        this.load();
    }

    async add() {
        await this.editUserModalRef.current.open({
        });
        this.load();
    }
    
    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            users
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel sticky">

                <h1>Users & Sweeps</h1>

                <button type="button" className="ms-auto btn btn-primary" onClick={() => this.add()}>
                    Add User
                </button>

            </section>

            <section>

                <SuperTable
                    className="users-table table table-bordered clickable mb-0"
                    rows={users}
                    keyAccessor={user => user.id}
                    cellPropsAccessor={(colInfo, user) => {
                        if (user && colInfo.name == 'colour' && user.diaryBackColour) {
                            return {
                                style: {
                                    backgroundColor: user.diaryBackColour,
                                    color: user.diaryTextColour
                                }
                            };
                        }
                    }}
                    cols={{
                        colour: {
                            label: '',
                            className: 'colour-col',
                            getValue: (colInfo, user, formattedValue, index) => `${index + 1}`
                        },
                        nickname: { label: 'Display Name', className: 'nickname-col' },
                        username: { label: 'Login', className: 'username-col' },
                        role: {
                            label: 'Role(s)',
                            className: 'roles-col',
                            getValue: (colInfo, user) => {
                                const roles = [];
                                if (user.isSweep) roles.push('Sweep');
                                if (user.isOffice) roles.push('Office');
                                if (user.isOwner) roles.push('Owner');
                                return roles.join(', ');
                            }
                        },
                        baseAddress: {
                            label: 'Base Address',
                            className: 'base-address-col',
                            getValue: (colInfo, user) => (user.baseAddress ? user.baseAddress.town : '')
                        }
                    }}
                    onClick={(user, e) => this.open(user.id)}
                    emptyText="No users to show"
                />

            </section>

            <EditUserModal
                ref={this.editUserModalRef}
            />

        </>);
    }
    
}

export default SetupUsers;