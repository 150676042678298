import React from 'react';
import moment from 'moment';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';
import DataHelpers from 'helpers/DataHelpers';
import Print from 'Print';

// Components
import Loader from 'components/common/Loader';
import SearchBox from 'components/common/SearchBox';
import PriceCalcModal from 'components/appt/PriceCalcModal';
import PreviewEmailModal from 'components/outgoing/PreviewEmailModal';
import EditCowlServiceModal from 'components/appt/EditCowlServiceModal';
import CustomerCommsModal from 'components/customer/CustomerCommunication/CustomerCommsModal';
import DatePicker from 'components/common/DatePicker';
import CurrencyInput from 'components/common/CurrencyInput';
import EditCustomerModal from 'components/customer/EditCustomerModal';
import EditApptResultsModal from 'components/appt/EditApptResultsModal';

//-----------------------------------------------------------------

class EditAppt extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            appt: null,
            appts: [],
            properties: [],
            sweeps: [],
            nonBookingApptTypes: [],
            suggestedAppts: null
        };

        this.changedFields = {};
        this.priceCalcModalRef = React.createRef();
        this.previewEmailModalRef = React.createRef();
        this.editCowlServiceModalRef = React.createRef();
        this.customerCommsModalRef = React.createRef();
        this.editCustomerModalRef = React.createRef();
        this.editApptResultsModalRef = React.createRef();

        this.form = new FormHelper({
            fields: {
                sweepUserID: {
                    label: 'Sweep',
                    type: 'single-select',
                    isReadOnly: () => this.state.isLocked,
                    getOptions: () => this.state.sweeps.map(s => ({
                        value: s.id,
                        text: s.nickname
                    }))
                },
                propertyID: {
                    label: 'Property',
                    type: 'single-select',
                    isReadOnly: () => this.state.isLocked,
                    getOptions: () => this.state.properties.map(p => ({
                        value: p.id,
                        text: p.name
                    })),
                    getLabelExtras: () => (this.state.appt.property &&
                        <div className="appt-editor-price-scheme ms-auto">
                            <span className="fa-solid fa-coins" />{' '}
                            Price Scheme: {this.state.appt.property.priceSchemeName || '(None)'}
                        </div>
                    ),
                    getExtras: () => this.state.appt.property && !!this.state.appt.property.notes && (
                        <div className="appt-editor-important-notes mb-3">
                            <span className="fa-solid fa-comment" />{' '}
                            {this.state.appt.property.notes}
                        </div>
                    )
                },
                date: {
                    label: 'Date',
                    type: 'date',
                    isReadOnly: () => this.state.isLocked
                },
                time: {
                    label: 'Time',
                    type: 'single-select',
                    blankText: '(Select)',
                    getOptions: () => ReferenceHelpers.listTimes().map(t => ({
                        value: t,
                        text: `${t}`
                    })),
                    isReadOnly: () => this.state.isLocked
                },
                duration: {
                    label: 'Duration',
                    //type: 'number',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.Durations.map(d => ({
                        value: d,
                        text: `${d}`
                    })),
                    isReadOnly: () => this.state.isLocked//,
                    //props: {
                    //    step: 5,
                    //    min: 0
                    //},
                },
                servicePrice: {
                    label: 'Service price',
                    type: 'currency',
                    getLabelExtras: () => (
                        <button type="button" className="btn btn-sm btn-primary ms-auto" title="Quotation" onClick={() => this.showPriceCalcModal()}>
                            <span className="fa fa-clipboard"></span>
                        </button>
                    )
                },
                productPrice: {
                    label: 'Product price',
                    type: 'currency',
                    isReadOnly: true
                },
                price: {
                    label: 'Total price',
                    type: 'currency',
                    isReadOnly: true
                },
                notes: {
                    label: 'Notes',
                    type: 'expanding-text',
                    rows: 2
                },
                skipBookingNotification: {
                    boxLabel: 'Skip booking notification',
                    type: 'checkbox',
                },
                isRecurring: {
                    boxLabel: 'Recurring appointment',
                    type: 'checkbox',
                    isReadOnly: () => !!this.state.appt.id
                }
            },
            getValue: (fieldName) => this.state.appt[fieldName],
            setValue: (fieldName, value) => {
                this.updateFields({ [fieldName]: value });
                this.isChanged = true;
            }
        });

        this.recurringApptForm = new FormHelper({
            fields: {
                startDate: {
                    label: 'Start date',
                    type: 'date',
                    isReadOnly: () => (this.state.appt && this.state.appt.recurringAppt && this.state.appt.recurringAppt.id)
                },
                endDate: {
                    label: 'End date',
                    type: 'date'
                },
                frequencyType: {
                    label: 'Frequency type',
                    type: 'single-select',
                    getOptions: () => [
                        { value: 'Days', text: 'Days' },
                        { value: 'Weeks', text: 'Weeks' },
                        { value: 'Months', text: 'Months' },
                        { value: 'Years', text: 'Years' }
                    ]
                },
                frequency: {
                    label: 'Frequency',
                    type: 'number'
                }
            },
            getValue: (fieldName) => this.state.appt.recurringAppt[fieldName],
            setValue: (fieldName, value) => this.updateRecurringApptFields({ [fieldName]: value })
        });
    }

    componentDidMount() {
        this.load();
    }

    componentDidUpdate(oldProps) {
        if (this.props.id != oldProps.id) {
            this.load();
        }
        const fields = {};
        let fieldsChanged = false;
        if (this.props.date != oldProps.date) {
            fields.date = this.props.date;
            fieldsChanged = true;
        }
        if (this.props.time != oldProps.time) {
            fields.time = this.props.time;
            fieldsChanged = true;
        }
        if (this.props.sweepUserID != oldProps.sweepUserID) {
            fields.sweepUserID = this.props.sweepUserID;
            fieldsChanged = true;
        }
        if (this.props.duration != oldProps.duration) {
            fields.duration = this.props.duration;
            fieldsChanged = true;
        }
        if (fieldsChanged) {
            this.updateFields(fields);
            this.isChanged = true;
        }
    }

    async load(silent) {
        return new Promise(async (resolve, reject) => {
            const { id, date, time, customerID, propertyID, rebookID, duration } = this.props;
            let { sweepUserID } = this.props;
            let appt;

            // Load related entities
            const sweeps = await API.call('user/list', { isSweep: true });
            const serviceTypes = await API.call('service-type/list');
            const paymentMethods = await API.call('payment-method/list');
            const serviceTypesByCode = {};
            const serviceTypesByID = {};
            serviceTypes.forEach(st => {
                serviceTypesByCode[st.code] = st;
                serviceTypesByID[st.id] = st;
            });

            // Load appt
            if (id) {
                if (!silent) {
                    this.setState({ isLoading: true });
                }
                appt = await API.call(`appt/get/${id}`);
                appt.isRecurring = !!appt.recurringAppt;
                this.updateTotal(appt);
            } else {
                if (!sweepUserID && sweeps.length > 0) {
                    sweepUserID = sweeps[0].id;
                }
                appt = {
                    date, time, sweepUserID, customerID, propertyID, rebookID, duration,
                    sweep: (sweepUserID ? sweeps.find(s => s.id == sweepUserID) : null),
                    status: 'Open',
                    services: [],
                    payments: [],
                    notifications: []
                };
                this.isChanged = true;
            }

            // Update UI
            this.setState({
                isLoading: false,
                appt,
                isLocked: (!!appt.id && appt.status != 'Open'),
                sweeps,
                serviceTypes,
                serviceTypesByCode,
                serviceTypesByID,
                paymentMethods
            }, async () => {
                await this.loadCustomer();
                if (appt.customerID) {
                    await this.loadProperties();
                    await this.loadRebooks();
                }
                if (this.props.onApptUpdate) {
                    this.props.onApptUpdate(appt);
                }
                if (this.props.onUpdateControlPanel) {
                    this.props.onUpdateControlPanel(this.renderControlPanel());
                }

                // Load non-booking appt types silently
                const nonBookingApptTypes = await API.call('non-booking-appt-type/list');
                this.setState({
                    nonBookingApptTypes
                }, resolve);
            });
        });
    }

    loadServicePrice() {
        clearTimeout(this.loadPriceTimeout);
        this.loadPriceTimeout = setTimeout(async () => {
            const apptPricing = await API.call('appt/get-price', this.state.appt);
            const services = [...this.state.appt.services];
            services.forEach(service => {
                const newService = apptPricing.services.find(ast => ast.serviceTypeID == service.serviceTypeID && ast.chimneyID == service.chimneyID);
                if (newService) {
                    service.price = newService.price;
                } else {
                    service.price = 0;
                }
            });
            this.updateFields({
                servicePrice: apptPricing.servicePrice,
                services
            }, null, true);
            if (apptPricing.servicePrice != this.state.appt.servicePrice) {
                this.isChanged = true;
            }
        }, 100);
    }
    
    loadDuration() {
        clearTimeout(this.loadDurationTimeout);
        this.loadDurationTimeout = setTimeout(async () => {
            const duration = await API.call('appt/get-duration', this.state.appt);
            if (duration != this.state.appt.duration) {
                this.updateFields({ duration });
                this.isChanged = true;
            }
        }, 100);
    }
    
    async save(appt) {
        return new Promise(async (resolve) => {
            if (!appt) {
                appt = this.state.appt;
            }
            if (!this.state.isChanged && appt.id) {
                return resolve(appt.id);
            }
            
            const { onSave } = this.props;
            this.setState({ isLoading: true });
            this.changedFields = {};
            try {
                if (this.isChanged) {
                    const { id, sendApptBookedNotification } = await API.call('appt/save', appt);
                    this.updateFields({
                        id
                    }, async () => {
                        this.setState({
                            isLoading: false,
                            isChanged: !appt.isDraft
                        });
                        if (onSave && !appt.isDraft) {
                            onSave(appt);
                        }
                        resolve({ id, sendApptBookedNotification });
                    });
                } else {
                    if (onSave && !appt.isDraft) {
                        onSave(appt);
                    }
                    resolve({ id: appt.id, sendApptBookedNotification: false });
                }
            } catch (error) {
                this.setState({
                    isLoading: false
                }, () => {
                    alert(error); 
                });
            }
        });
    }
    
    async saveAndClose() {
        const { appt } = this.state;
        const hasSentBookingNotification = appt.notifications.some(n => n.type == 'ApptBooked');
        
        // Non-booking appt type - just save once
        if (appt.nonBookingApptTypeID || appt.isRecurring) {
            await this.save();
            this.props.onClose(true);
            return;
        }

        const hasUncheckedService = appt.property.chimneys.some(chimney => {
            const sweepService = this.findService(chimney.id, 'sweep');
            const nestService = this.findService(chimney.id, 'nest');
            const cowlService = this.findService(chimney.id, 'cowl');
            const cctvService = this.findService(chimney.id, 'cctv');

            if (sweepService && !sweepService.price) return true;
            if (nestService && !nestService.price) return true;
            if (cowlService && !cowlService.price) return true;
            if (cctvService && !cctvService.price) return true;

            return false;
        });

        if (appt.status === 'Open' && hasUncheckedService) {
            alert('All selected chimney services must have a price. Please set all prices and try saving again.');
            return;
        }

        // Check for price
        if (appt.status === 'Open' && !appt.servicePrice) {
            const confirm = window.confirm('The appointment has no price (which may be because there is no price scheme set). Are you sure you want to continue?');
            if (!confirm) return;
        }

        // Check for 0-value payments
        if (appt.payments && appt.payments.some(ap => !ap.amount)) {
            alert('There are blank payments on this appointment. Please make sure that all payments have values or delete them if they were added in error.');
            return;
        }

        // If we've already sent a notification, do a full save
        if (hasSentBookingNotification) {
            const { sendApptBookedNotification } = await this.save();

            // If we have rescheduled the appointment, offer to re-send the booking notification
            if (sendApptBookedNotification) {
                await this.confirmSendApptBookedNotification(appt.id);
            }

            this.props.onClose(true);
            return;
        }
        
        // Otherwise, draft save to determine whether we should send a booking notification
        appt.isDraft = true;
        const { sendApptBookedNotification } = await this.save(appt);

        // Then save fully
        this.updateFields({
            isDraft: false
        }, async () => {
            const { id } = await this.save();
                
            if (sendApptBookedNotification && !appt.skipBookingNotification) {
                const send = await this.confirmSendApptBookedNotification(id);

                // If we don't send, tick 'skip booking notification' automatically
                if (!send) {
                    this.updateFields({
                        skipBookingNotification: true
                    }, () => {
                        this.save();
                    });
                }
            }
            this.props.onClose(true);
        });
    }

    async emailInvoice() {
        const { appt } = this.state;
        const email = {
            type: 'Invoice',
            apptID: appt.id
        };
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email
        });
        if (send) {
            await API.call('email/send', email);
            window.alert('The invoice has been sent.');
        }
    }

    async emailReceipt() {
        const { appt } = this.state;
        const email = {
            type: 'Receipt',
            apptID: appt.id
        };
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email
        });
        if (send) {
            await API.call('email/send', email);
            window.alert('The receipt has been sent.');
        }
    }

    updateFields(fields, callback, dontLoadPrice) {
        const appt = { ...this.state.appt };
        let anyChanged = false, loadCustomer = false, loadProperty = false, loadPrice = false, loadDuration = false;

        for (let fieldName in fields) {
            const oldValue = appt[fieldName];
            const value = fields[fieldName];
            if (value != oldValue) {
                anyChanged = true;
                appt[fieldName] = value;
                this.changedFields[fieldName] = true;

                switch (fieldName) {
                    case 'sweepUserID':
                        appt.sweep = this.state.sweeps.find(s => s.id == appt.sweepUserID);
                        break;
                    case 'customerID':
                        loadCustomer = true;
                        loadPrice = true;
                        break;
                    case 'propertyID':
                        appt.property = this.state.properties.find(p => p.id == appt.propertyID);
                        loadPrice = true;
                        loadProperty = true;
                        break;
                    case 'services':
                        if (appt.status == 'Open') {
                            loadPrice = !dontLoadPrice;
                            loadDuration = !dontLoadPrice;
                        }
                        appt.servicePrice = 0;
                        appt.services.forEach(ast => {
                            appt.servicePrice += (ast.price || 0);
                        });
                        this.updateTotal(appt);
                        break;
                    case 'date':
                        if (this.props.onSetDate) {
                            this.props.onSetDate(value);
                        }
                        break;
                    case 'payments':
                    case 'servicePrice':
                    case 'productPrice':
                        this.updateTotal(appt);
                        break;
                    case 'isRecurring':
                        if (value) {
                            if (!appt.recurringAppt) {
                                appt.recurringAppt = {
                                    startDate: moment(appt.date).format('YYYY-MM-DD'),
                                    frequencyType: 'Days',
                                    frequency: 1
                                };
                            }
                        } else {
                            appt.recurringAppt = null;
                        }
                        break;
                }
            }
        }

        if (anyChanged) {
            appt.balance = this.getBalance(appt);

            this.setState({
                appt,
                isLoadingProperties: loadCustomer,
                isChanged: true,
                suggestedAppts: (loadCustomer ? null : this.state.suggestedAppts)
            }, async () => {
                if (loadCustomer) {
                    await this.loadCustomer();
                    await this.loadProperties();
                }
                if (loadProperty) {
                    this.loadProperty(false);
                }
                if (loadPrice) {
                    this.loadServicePrice();
                }
                if (loadDuration) {
                    this.loadDuration();
                }
                if (callback) callback();
                if (this.props.onApptUpdate) {
                    this.props.onApptUpdate(appt);
                }
                if (this.props.onUpdateControlPanel) {
                    this.props.onUpdateControlPanel(this.renderControlPanel());
                }
            });
        } else if (callback) {
            callback();
        }
    }

    updateRecurringApptFields(fields) {
        const recurringAppt = { ...this.state.appt.recurringAppt };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            recurringAppt[fieldName] = value;
        }
        this.updateFields({ recurringAppt });
        this.isChanged = true;
    }

    loadCustomer() {
        return new Promise(async (resolve) => {
            const { customerID } = this.state.appt;
            const fields = {};
            if (customerID) {
                this.setState({ isLoadingCustomer: true });
                fields.customer = await API.call('customer/get/' + customerID);
            } else {
                fields.customer = null;
                fields.property = null;
            }
            this.updateFields(fields, () => {
                this.setState({
                    isLoadingCustomer: false
                }, resolve);
            });
        });
    }
    
    loadRebooks() {
        return new Promise(async (resolve) => {
            // Load rebooks
            let getForCustomerPropertyDto = {
                customerID: this.state.appt.customerID,
                propertyID: this.state.appt.propertyID
            };

            let customerRebooks = {
                rebooks: await API.call(`rebook/get-for-customer-property`, getForCustomerPropertyDto)
            };
            
            this.setState({ customerRebooks: customerRebooks})
        });
    }

    loadProperties() {
        return new Promise(async (resolve) => {
            const { customerID, propertyID, property } = this.state.appt;

            if (customerID) {
                this.setState({ isLoadingProperties: true });
                const properties = await API.call('property/list', { customerID });

                this.updateFields({
                    propertyID: propertyID || (properties.length > 0 ? properties[0].id : null),
                    property: property || (properties.length > 0 ? properties[0] : null)
                }, () => {
                    this.setState({
                        properties,
                        isLoadingProperties: false
                    });
                    this.loadProperty(false);
                    resolve();
                });
            } else {
                this.updateFields({
                    propertyID: null,
                    property: null
                }, () => {
                    this.setState({ properties: [] });
                    this.loadProperty(false);
                    resolve();
                });
            }
        });
    }

    async loadProperty(force) {
        const { appt, serviceTypesByCode } = this.state;
        if (appt.property && appt.property.chimneys && !force) {
            const oldPropertyID = (appt.property || { id: 0 }).id;
            if (appt.propertyID == oldPropertyID) {
                return;
            }
        }
        let property;
        if (appt.propertyID) {
            this.setState({ isLoadingProperty: true });
            property = await API.call('property/get/' + appt.propertyID);
        }
        this.updateFields({
            property
        }, async () => {
            // Pre-tick services
            if (appt.services.length == 0 && property.chimneys && appt.status == 'Open') {
                const services = [];
                property.chimneys.forEach(c => {
                    if (c.isInUse) {
                        services.push({
                            chimneyID: c.id,
                            serviceTypeID: serviceTypesByCode['sweep'].id
                        });
                    }
                });
                this.updateFields({ services });
            }
            this.setState({ isLoadingProperty: false });

            // If new appt, check for existing appt at this property
            if (!appt.id) {
                const { id, date } = await API.call('appt/get-open-appt/' + appt.propertyID); 
                this.setState({
                    openApptID: id,
                    openApptDate: (date ? moment(date).format('YYYY-MM-DD') : null)
                });
            }
        });
    }

    async confirmCancel() {
        const { appt } = this.state;
        const confirm = window.confirm('Are you sure you want to cancel this appointment?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('appt/cancel/' + appt.id);
            this.props.onClose(true);
        }
    }

    async confirmCancelRecurring() {
        const { appt } = this.state;
        const confirm = window.confirm('Delete this recurring appointment (this will delete all future occurrences)?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('appt/delete-recurring/' + appt.recurringAppt.id);
            this.props.onClose(true);
        }
    }

    findServiceIndex(serviceTypeCode, chimneyID) {
        const { appt, serviceTypesByCode } = this.state;
        const serviceType = serviceTypesByCode[serviceTypeCode];
        if (serviceType) {
            const index = appt.services.findIndex(s => s.serviceTypeID == serviceType.id && s.chimneyID == chimneyID);
            return index;
        }
        return -1;
    }

    findService(chimneyID, serviceTypeCode) {
        const { appt } = this.state;
        const index = this.findServiceIndex(serviceTypeCode, chimneyID);
        return (index != -1 ? appt.services[index] : null);
    };

    toggleService(serviceTypeCode, chimneyID) {
        const { appt, serviceTypesByCode } = this.state;
        const serviceType = serviceTypesByCode[serviceTypeCode];
        const services = [...appt.services];
        const index = this.findServiceIndex(serviceTypeCode, chimneyID);
        let editCowlService = false;

        if (index == -1) {
            const service = {
                chimneyID,
                serviceTypeID: serviceType.id
            };
            if (serviceTypeCode == 'cowl') {
                editCowlService = true;
                service.cowlQuantity = 1;
            }
            services.push(service);

            // Untick sweep if this is a nest or a cowl
            if (serviceTypeCode == 'nest' || serviceTypeCode == 'cowl') {
                const sweepIndex = this.findServiceIndex('sweep', chimneyID);
                if (sweepIndex != -1) {
                    services.splice(sweepIndex, 1);
                }
            }
        } else {
            services.splice(index, 1);
        }
        this.updateFields({
            services
        }, () => {
            if (editCowlService) {
                this.editCowlService(chimneyID);
            }
        });
    }

    async editCowlService(chimneyID) {
        const { appt } = this.state;
        const services = [...appt.services];
        const cowlIndex = this.findServiceIndex('cowl', chimneyID);
        const service = await this.editCowlServiceModalRef.current.open({
            service: services[cowlIndex]
        });
        this.updateService(service);
    }

    updateService(service, dontLoadPrice) {
        const { appt } = this.state;
        const services = [...appt.services];
        const index = services.findIndex(ast => ast.chimneyID == service.chimneyID && ast.serviceTypeID == service.serviceTypeID);
        services[index] = service;
        this.updateFields({ services }, null, dontLoadPrice);
        this.isChanged = true;
    }

    async showPriceCalcModal() {
        const { appt } = this.state;

        // Calculate services to pre-populate quotation
        const services = {};
        appt.services.forEach(s => {
            services[s.serviceTypeID] = (services[s.serviceTypeID] || 0) + 1;
        });

        // Show modal
        const result = await this.priceCalcModalRef.current.open({
            priceSchemeID: appt.property.priceSchemeID,
            services,
            canUsePrice: true
        });

        // Update the price scheme for this property
        if (result.priceSchemeID) {
            await API.call('property/update-price-scheme', {
                priceSchemeID: result.priceSchemeID,
                propertyID: appt.propertyID,
            });
            this.loadProperty(true);
        }

        // Load price back in
        this.loadServicePrice();
        this.updateFields({ servicePrice: result.price });
        this.isChanged = true;
    }
    
    async printCertificate(isInline) {
        await this.save();
        await Print.url('/api/appt/get-certificate-pdf/' + this.state.appt.id, {
            isInline
        });
        this.setState({
            isLoading: false
        }, () => {
            setTimeout(() => {
                const markSent = window.confirm('Mark the certificate as sent?');
                if (markSent) {
                    this.markCertSent();
                }
            }, 500);
        });
    }

    async markCertSent() {
        await API.call('appt/mark-cert-sent/' + this.state.appt.id);
        if (this.props.onSendCert) {
            this.props.onSendCert();
        }
    }

    async printReceipt(isInline) {
        await this.save();
        await Print.url('/api/appt/get-receipt-pdf/' + this.state.appt.id, {
            isInline
        });
        this.setState({ isLoading: false });
    }

    async printInvoice(isInline) {
        await this.save();
        await Print.url('/api/appt/get-invoice-pdf/' + this.state.appt.id, {
            isInline
        });
        this.setState({ isLoading: false });
    }

    async addCustomer() {
        const { id } = await this.editCustomerModalRef.current.open({
            cameFrom: 'appt'
        });
        this.updateFields({
            customerID: id
        });
        this.isChanged = true;
        this.loadServicePrice();
    }

    addPayment() {
        const { appt } = this.state;
        const payments = [...appt.payments];
        let id = 0;
        payments.forEach(ap => {
            if (ap.id < id) id = ap.id;
        });
        id--;
        payments.push({
            id,
            date: moment().startOf('day').toDate(),
            isDeposit: (appt.status == 'Open')
        });
        this.focusInput = `payment-${id}`;
        this.updateFields({ payments });
        this.isChanged = true;
    }

    updatePayment(id, fields) {
        const payments = [...this.state.appt.payments];
        const index = payments.findIndex(ap => ap.id == id);
        const payment = { ...payments[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            payment[fieldName] = value;
        }
        payments[index] = payment;
        this.updateFields({ payments });
        this.isChanged = true;
    }

    updateTotal(appt) {
        appt.price = (appt.servicePrice || 0) + appt.productPrice;
        appt.balance = this.getBalance(appt);
    }

    getBalance(appt) {
        let balance = (appt.servicePrice || 0) + (appt.productPrice || 0);
        if (appt.payments) {
            appt.payments.forEach(ap => {
                balance -= (ap.amount || 0);
            });
        }
        return balance;
    }

    async loadSuggestedAppts(delta) {
        if (this.state.isLoadingSuggestedAppts) {
            return;
        }
        let { appt, suggestedApptsStartDate } = this.state;
        if (suggestedApptsStartDate) {
            suggestedApptsStartDate = moment(suggestedApptsStartDate).add(delta || 0, 'days');
        } else {
            suggestedApptsStartDate = moment().startOf('day');
        }
        suggestedApptsStartDate = moment(suggestedApptsStartDate).format('YYYY-MM-DD');
        this.setState({
            suggestedApptsStartDate,
            isLoadingSuggestedAppts: true,
            suggestedAppts: null
        });
        const { suggestedAppts, suggestedApptSettings } = await API.call('auto-planner/list-suggested-appts', {
            dateFrom: suggestedApptsStartDate,
            newAppt: appt
        });
        this.setState({
            isLoadingSuggestedAppts: false,
            suggestedAppts,
            suggestedApptSettings
        });
    }

    selectSuggestedApptDate(date) {
        this.updateFields({ date });
        if (this.props.onSetDate) {
            this.props.onSetDate(date);
        }
        this.isChanged = true;
    }

    getScaleColour(value, min, max) {
        // e.g min 500 max 600, value 550
        // (550 - 500) / (600 - 500) = 50 / 100 = 0.5
        const delta = max - min;
        const normalised = (delta > 0 ? (value - min) / delta : 0);
        const hue = 90 - normalised * 90;
        const col = DataHelpers.hslToHex(hue, 75, 90);
        return col;
    }

    selectSuggestedAppt(appt) {
        this.updateFields({
            date: appt.date,
            time: appt.time,
            sweepUserID: appt.sweep.id
        });
        this.isChanged = true;
    }

    confirmRemovePayment(id) {
        const confirm = window.confirm('Are you sure you want to remove this payment?');
        if (confirm) {
            const payments = [...this.state.appt.payments];
            const index = payments.find(p => p.id == id);
            payments.splice(index, 1);
            this.updateFields({ payments });
            this.isChanged = true;
        }
    }

    async confirmReopen() {
        const confirm = window.confirm('Are you sure you want to re-open this appointment?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('appt/reopen/' + this.state.appt.id);
            this.props.onClose(true);
        }
    }

    showCustomerComms() {
        const { appt } = this.state;
        
        this.customerCommsModalRef.current.open({
            customerID: appt.customerID,
            propertyID: appt.propertyID
        });
    }

    async confirmSendApptBookedNotification(apptID) {
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email: {
                type: 'ApptBooked',
                apptID
            }
        });
        if (send) {
            await API.call('appt/trigger-notification', {
                apptID,
                type: 'ApptBooked'
            });
        }
        return send;
    }

    async confirmResendCompletedNotification() {
        const { appt } = this.state;
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email: {
                type: 'ApptCompleted',
                apptID: appt.id
            }
        });
        if (send) {
            await API.call('appt/trigger-notification', {
                apptID: this.state.appt.id,
                type: 'ApptCompleted'
            });
            alert('The notification has been sent.');
        }
    }

    async editCustomer(customerID, propertyID) {
        await this.editCustomerModalRef.current.open({
            id: customerID,
            propertyID,
            cameFrom: 'appt'
        });
        this.loadCustomer();
        this.loadProperties();
        if (propertyID) {
            this.loadProperty(true);
            this.loadServicePrice();
        }
    }

    async showEditResultsModal() {
        const { productPrice } = await this.editApptResultsModalRef.current.open({
            id: this.state.appt.id
        });
        this.updateFields({
            productPrice
        });
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {

        setTimeout(() => {
            this.focusInput = null;
        });
        
        return (<>

            {this.renderInner()}

            <PriceCalcModal ref={this.priceCalcModalRef} />
            <PreviewEmailModal ref={this.previewEmailModalRef} />
            <EditCowlServiceModal ref={this.editCowlServiceModalRef} />
            <CustomerCommsModal ref={this.customerCommsModalRef} {...this.props} />
            <EditCustomerModal ref={this.editCustomerModalRef} {...this.props} />
            <EditApptResultsModal ref={this.editApptResultsModalRef} />
        </>);
    }

    renderInner() {
        const {
            isLoading,
            isLoadingProperties,
            appt,
            nonBookingApptTypes,
            openApptID,
            openApptDate
        } = this.state;
        const { showWarningOverXMins } = this.props.loginInfo.account;
        const { hasControlPanel, warnTravelTime } = this.props;
        
        if (isLoading) {
            return (<Loader />);
        }

        return (
            <form onSubmit={e => { e.preventDefault(); e.stopPropagation(); this.saveAndClose() }} className="edit-appt-form">

                <fieldset>

                    <div className="row">

                        <div className="col-md-6">

                            {!appt.nonBookingApptTypeID &&
                                <div className="form-group">
                                    <label className="form-label">Customer</label>
                                    {this.renderCustomer()}
                                </div>
                            }

                            {!appt.customer &&
                                <div className="form-group">
                                    <label className="form-label">Non-Booking Time</label>
                                    <select
                                        value={appt.nonBookingApptTypeID || ''}
                                        className="form-control"
                                        onChange={e => {
                                            this.updateFields({ nonBookingApptTypeID: e.target.value });
                                            this.isChanged = true;
                                        }}
                                    >
                                        <option value="">(Select)</option>
                                        {nonBookingApptTypes.map(nbat =>
                                            <option key={nbat.id} value={nbat.id}>
                                                {nbat.name}
                                            </option>
                                        )}
                                    </select>
                                </div>
                            }

                        </div>

                        {appt.customer &&
                            <div className="col-md-2">

                                <div className="form-group">
                                    <label className="form-label">Activity</label>
                                    <button type="button" className="btn btn-secondary" onClick={() => this.showCustomerComms()}>
                                        View
                                    </button>
                                </div>

                            </div>
                        }

                        <div className={appt.customer ? 'col-md-4' : 'col-md-6'}>

                            {this.form.render('sweepUserID')}

                        </div>

                    </div>

                    {appt.customer && !!appt.customer.doNotSweepReason &&
                        <div className="warning">
                            <span className="fa-solid fa-triangle-exclamation" />{' '}
                            DO NOT SWEEP: {appt.customer.doNotSweepReason}
                        </div>
                    }

                    {isLoadingProperties ?
                        <div className="form-group">
                            <label className="form-label">Property</label>
                            <div className="form-control-plaintext"><Loader isInline /></div>
                        </div> :
                        !!appt.customerID ? this.form.render('propertyID') :
                            null
                    }

                    <div className="row">

                        <div className="col-md-3">

                            {this.form.render('date')}

                        </div>

                        <div className="col-md-3">

                            {this.form.render('time')}

                        </div>

                        <div className="col-md-3">

                            {this.form.render('duration')}

                        </div>

                        <div className="col-md-3">

                            {!!appt.id && !appt.nonBookingApptTypeID &&
                                <div className="form-group">
                                    <label className="form-label">Status</label>
                                    <p className="form-control-plaintext">
                                        {appt.status}
                                        {(appt.status == 'Completed' || appt.status == 'Cancelled') &&
                                            <button className="btn btn-primary btn-sm ms-2" title="Re-open appointment" onClick={() => this.confirmReopen()}>
                                                <span className="fa-solid fa-undo" />
                                            </button>
                                        }
                                    </p>
                                </div>
                            }

                        </div>

                    </div>

                    {warnTravelTime && appt.property && <>

                        <button type="button" className="btn btn-danger w-100 mb-3" onClick={() => {
                            alert('This appointment would go over the sweep\'s limit of ' + showWarningOverXMins + ' mins travel time')
                        }}>
                            <span className="fa fa-triangle-exclamation"></span>{' '}
                            Travel time over {showWarningOverXMins}min limit
                        </button>

                    </>}

                    {!!openApptID && appt.property && <>

                        <button type="button" className="btn btn-danger w-100 mb-3" onClick={() => {
                            this.props.history.push(`day-sheet?apptID=${openApptID}&date=${openApptDate}`);
                            window.location.reload();
                        }}>
                            <span className="fa fa-triangle-exclamation"></span>{' '}
                            There is already an open booking at this property (click to open)
                        </button>

                    </>}

                    {appt.property && <>

                        {this.renderServices()}

                        {this.renderSuggestedAppts()}

                        {this.form.render('notes')}

                        <div className="row">

                            <div className="col-md-4">

                                {this.form.render('servicePrice')}

                            </div>

                            {appt.status == 'Completed' && <>
                            
                                <div className="col-md-4">

                                    {this.form.render('productPrice')}

                                </div>

                                <div className="col-md-4">

                                    {this.form.render('price')}

                                </div>
                            </>}

                        </div>

                        {this.renderPayments()}

                        {this.renderNotifications()}

                        {this.renderInvoice()}

                        {this.renderOther()}

                    </>}

                    {!!appt.nonBookingApptTypeID && this.renderNonBookingAppt()}

                </fieldset>

                {hasControlPanel &&
                    <div className="control-panel">

                        {this.renderControlPanel()}

                    </div>
                }

            </form>
        );
    }

    renderControlPanel() {
        const {
            appt
        } = this.state;
        const { onClose, onReschedule } = this.props;
        const hasSentBookingNotification = appt.notifications.some(n => n.type == 'ApptBooked');

        return (<>

            {onClose &&
                <button type="button" className="btn btn-secondary me-auto" onClick={() => onClose(false)}>
                    Cancel
                </button>
            }

            {!!appt.id &&
                <div className="btn-group dropup">
                    <button type="button" className="btn btn-tertiary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        More...
                    </button>
                    <ul className="dropdown-menu">
                        {(appt.status == 'Completed' || !appt.customerID) && <>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.showEditResultsModal();
                                }}>
                                    <span className="fa-solid fa-pencil-alt" />{' '}Edit results
                                </a>
                            </li>
                            <li className="dropdown-divider" />
                        </>}
                        {(appt.status == 'Open' || !appt.customerID) && <>
                            {onReschedule &&
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        onReschedule();
                                    }}>
                                        <span className="fa-solid fa-calendar-days" />{' '}Reschedule
                                    </a>
                                </li>
                            }
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.confirmCancel();
                                }}>
                                    <span className="fa-solid fa-xmark" />{' '}Cancel appointment
                                </a>
                            </li>
                            {appt.recurringAppt &&
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.confirmCancelRecurring();
                                    }}>
                                        <span className="fa-solid fa-xmark" />{' '}Cancel recurring appointment
                                    </a>
                                </li>
                            }
                        </>}
                        {appt.status == 'Open' && !!appt.customerID &&
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.confirmSendApptBookedNotification(appt.id);
                                }}>
                                    <span className="fa-solid fa-envelope" />{' '}Send booking email...
                                </a>
                            </li>
                        }
                        {appt.status == 'Completed' && !!appt.customerID && <>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printCertificate(true);
                                }}>
                                    <span className="fa-solid fa-certificate" />{' '}Print certificate
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printCertificate(false);
                                }}>
                                    <span className="fa-solid fa-certificate" />{' '}Download certificate
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.confirmResendCompletedNotification();
                                }}>
                                    <span className="fa-solid fa-envelope" />{' '}Email certificate...
                                </a>
                            </li>
                            <li className="dropdown-divider" />
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printInvoice(true);
                                }}>
                                    <span className="fa-solid fa-file-invoice" />{' '}Print invoice
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printInvoice(false);
                                }}>
                                    <span className="fa-solid fa-file-invoice" />{' '}Download invoice
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.emailInvoice();
                                }}>
                                    <span className="fa-solid fa-envelope" />{' '}Email invoice...
                                </a>
                            </li>
                        </>}
                        {appt.payments.length > 0 && <>
                            <li className="dropdown-divider" />
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printReceipt(true);
                                }}>
                                    <span className="fa-solid fa-receipt" />{' '}Print receipt
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.printReceipt(false);
                                }}>
                                    <span className="fa-solid fa-receipt" />{' '}Download receipt
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item" href="#" onClick={e => {
                                    e.preventDefault();
                                    this.emailReceipt();
                                }}>
                                    <span className="fa-solid fa-envelope" />{' '}Email receipt...
                                </a>
                            </li>
                        </>}
                    </ul>
                </div>
            }

            <button type="button" className="btn btn-primary ms-auto" onClick={() => this.saveAndClose()}>
                Save & Close
            </button>

        </>);
    }

    renderCustomer() {
        const { isLoadingCustomer, appt, isLocked } = this.state;

        if (isLoadingCustomer) {
            return (
                <div className="form-control-plaintext">
                    <Loader isInline />
                </div>
            );
        }
        if (appt.customer) {
            return (<>
                <div className="form-control-plaintext">
                    {TextHelpers.formatName(appt.customer.title, appt.customer.firstName, appt.customer.lastName) || appt.customer.companyName}
                    <button type="button" className="btn btn-sm btn-primary ms-2" title="View customer" onClick={() => this.editCustomer(appt.customer.id, appt.property ? appt.property.id : null)}
                    >
                        <span className="fa fa-search" />
                    </button>
                    {!isLocked &&
                        <button type="button" className="btn btn-sm btn-danger ms-2" title="Select a different customer" onClick={() => {
                            this.updateFields({ customerID: null });
                            this.isChanged = true;
                        }}>
                            <span className="fa fa-times" />
                        </button>
                    }
                </div>

                <div className="appt-editor-email-address">
                    Email: {appt.customer.emailAddress || '(None)'}
                </div>

                {!!appt.customer.notes &&
                    <div className="appt-editor-important-notes">
                        <span className="fa-solid fa-comment" />{' '}
                        {appt.customer.notes}
                    </div>
                }
            </>);
        }

        if (isLocked) {
            return null;
        }

        setTimeout(() => {
            this.isRendered = true;
        }, 0);

        return (
            <SearchBox
                className="form-control"
                placeholder="Search..."
                minLength={2}
                autoFocus={!this.isRendered}
                search={async (query, setResults, dataObj) => {
                    dataObj.nonce = `${Math.random()}`;
                    const response = await API.call('search/customer-property', { query, nonce: dataObj.nonce });
                    if (response.nonce == dataObj.nonce) {
                        const customersFlattened = [];
                        response.results.forEach(c => {
                            if (!ReferenceHelpers.isIndividual(c.customerType)) {
                                customersFlattened.push({
                                    ...c
                                });
                            }
                            c.properties.forEach(p => {
                                const customerAndProperty = {
                                    ...c,
                                    isProperty: true,
                                    property: p
                                };
                                customersFlattened.push(customerAndProperty);
                            });
                        });
                        setResults(customersFlattened);
                    }
                }}
                renderInputExtras={(query, results) =>
                    <button type="button" className="btn btn-tertiary" onClick={() => this.addCustomer()}>
                        <span className="fa-solid fa-plus" />
                    </button>
                }
                //renderExtras={(query, results) => 
                //    <div className="search-results-button">
                //        <button type="button" className="btn btn-tertiary" onClick={() => this.addCustomer()}>
                //            <span className="fa-solid fa-plus" />{' '}
                //            New Customer
                //        </button>
                //    </div>
                //}
                onClickResult={customer => {
                    this.updateFields({
                        customerID: customer.id,
                        propertyID: (customer.property ? customer.property.id : null)
                    });
                    this.isChanged = true;
                }}
            />
        );
    }

    renderServices() {
        const { appt, isLocked } = this.state;

        if (!appt.property || !appt.property.chimneys) {
            return (<Loader />);
        }
        
        return (<>

            {this.renderChimneys()}

            <div className="form-group">
                <label className="form-label">Other Services</label>
                <div className="d-flex align-items-start">
                    <p className="form-control-plaintext flex-1 me-2">
                        {this.renderOtherServicesSummary()}
                    </p>

                    {!isLocked && this.renderOtherServicesSelector()}
                </div>
            </div>

        </>);
    }

    renderChimneys() {
        const { appt, isLoadingProperty, isLocked } = this.state;
        const { serviceTypes } = this.props.loginInfo.account;

        if (isLoadingProperty) {
            return (<Loader />);
        } else if (!appt.property || !appt.property.chimneys) {
            return null;
        }

        return (
            <table className="appt-chimneys-table table table-bordered">
                <thead>
                    <tr>
                        <th className="chimney-col">Chimney</th>
                        <th className="sweep-col">Sweep</th>
                        <th className="nest-col">Nest</th>
                        {serviceTypes.cowl &&
                            <th className="cowl-col">Cowl</th>
                        }
                        {serviceTypes.cctv &&
                            <th className="cctv-col">CCTV</th>
                        }
                    </tr>
                </thead>
                <tbody>
                    {appt.property.chimneys.map(chimney => {
                        const sweepService = this.findService(chimney.id, 'sweep');
                        const nestService = this.findService(chimney.id, 'nest');
                        const cowlService = this.findService(chimney.id, 'cowl');
                        const cctvService = this.findService(chimney.id, 'cctv');
                        
                        return (
                            <tr key={chimney.id}>
                                <td className="chimney-col">
                                    <strong>{chimney.chimneyType.name || 'Unspecified'}</strong>
                                    <br />
                                    {chimney.floor}{' '}floor{' '}{chimney.location}
                                    {!!chimney.notes &&
                                        <div className="appt-editor-important-notes">
                                            <span className="fa-solid fa-comment" />{' '}
                                            {chimney.notes}
                                        </div>
                                    }
                                    {!chimney.isInUse && <><br />(Not in use)</>}
                                </td>
                                <td className="sweep-col">
                                    <input
                                        type="checkbox"
                                        checked={!!sweepService || !!nestService}
                                        onChange={e => this.toggleService('sweep', chimney.id)}
                                        disabled={isLocked || !!nestService}
                                    />
                                    {!!sweepService && !nestService &&
                                        <CurrencyInput
                                            className="form-control"
                                            value={sweepService.price || ''}
                                            placeholder="Price..."
                                            onValueChange={(values) => {
                                                this.updateService({ ...sweepService, price: Number(values.value) || '' }, true);
                                            }}
                                        />
                                    }
                                </td>
                                <td className="nest-col">
                                    <input
                                        type="checkbox"
                                        checked={!!nestService}
                                        onChange={e => this.toggleService('nest', chimney.id)}
                                        disabled={isLocked}
                                    />
                                    {!!nestService &&
                                        <CurrencyInput
                                            className="form-control"
                                            value={nestService.price || ''}
                                            placeholder="Price..."
                                            onValueChange={(values) => {
                                                this.updateService({ ...nestService, price: Number(values.value) || '' }, true);
                                            }}
                                        />
                                    }
                                </td>
                                {serviceTypes.cowl &&
                                    <td className="cowl-col">
                                        <input
                                            type="checkbox"
                                            checked={!!cowlService}
                                            onChange={e => this.toggleService('cowl', chimney.id)}
                                            disabled={isLocked}
                                        />
                                        {!!cowlService && <>
                                            <button type="button" className="btn btn-primary btn-sm w-100" onClick={() => this.editCowlService(chimney.id)}>
                                                <span className="fa-solid fa-pencil-alt" />
                                            </button>
                                            <CurrencyInput
                                                className="form-control"
                                                value={cowlService.price || ''}
                                                placeholder="Price..."
                                                onValueChange={(values) => {
                                                    this.updateService({ ...cowlService, price: Number(values.value) || '' }, true);
                                                }}
                                            />
                                        </>}
                                    </td>
                                }
                                {serviceTypes.cctv &&
                                    <td className="cctv-col">
                                        <input
                                            type="checkbox"
                                            checked={!!cctvService}
                                            onChange={e => this.toggleService('cctv', chimney.id)}
                                            disabled={isLocked}
                                        />
                                    </td>
                                }
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }

    renderOtherServicesSummary() {
        const { appt, serviceTypesByID } = this.state;
        const otherServices = [];
        appt.services.forEach(ast => {
            const serviceType = serviceTypesByID[ast.serviceTypeID];
            if (serviceType && !serviceType.requiresChimney) {
                otherServices.push(serviceType.name);
            }
        });
        if (otherServices.length > 0) {
            return otherServices.join(', ');
        } else {
            return 'None';
        }
    }

    renderOtherServicesSelector() {
        const { serviceTypes, appt } = this.state;
        const otherServiceTypes = serviceTypes.filter(st => !st.requiresChimney);
        const selectedServiceTypes = {};
        appt.services.forEach(ast =>
            selectedServiceTypes[ast.serviceTypeID] = true
        );
        
        return (
            <div className="btn-group dropup">
                <button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    Select...
                </button>
                <ul className="dropdown-menu">
                    {otherServiceTypes.map(st =>
                        <li key={st.id}>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.toggleService(st.code, null);
                            }}>
                                {selectedServiceTypes[st.id] ?
                                    <span className="fa-solid fa-check" /> :
                                    <span className="fa-solid fa-xmark" />
                                }
                                {' '}{st.name}
                            </a>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

    renderPayments() {
        const { appt, paymentMethods } = this.state;

        let table;
        if (appt.payments.length == 0) {
            table = (
                <p className="form-control-plaintext">
                    No payments added
                    {appt.paymentType == 'SendInvoice' && <>{' '}(Send Invoice)</>}
                    {appt.paymentType == 'PaymentToFollow' && <>{' '}(Payment to Follow)</>}
                </p>
            );
        } else {
            table = (
                <table className="table table-bordered appt-payments-table">
                    <thead>
                        <tr>
                            <th className="date-col">Date</th>
                            <th className="amount-col">Amount</th>
                            <th className="method-col">Method</th>
                            <th className="deposit-col">Deposit</th>
                            <th className="actions-col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {appt.payments.map(ap =>
                            <React.Fragment key={ap.id}>
                                <tr>
                                    <td className="date-col">
                                        <DatePicker
                                            className="form-control"
                                            wrapperClassName="date-picker"
                                            value={ap.date ? new Date(ap.date) : null}
                                            onChange={date => this.updatePayment(ap.id, { date })}
                                        />
                                    </td>
                                    <td className="amount-col">
                                        <CurrencyInput
                                            className="form-control"
                                            value={ap.amount || ''}
                                            autoFocus={this.focusInput == `payment-${ap.id}`}
                                            onValueChange={(values) => {
                                                const amount = Number(values.value) || '';
                                                this.updatePayment(ap.id, { amount });
                                            }}
                                        />
                                    </td>
                                    <td className="method-col">
                                        <select
                                            className="form-control"
                                            value={ap.paymentMethodID || ''}
                                            onChange={e => this.updatePayment(ap.id, { paymentMethodID: e.target.value })}
                                        >
                                            <option value="">(Select)</option>
                                            {paymentMethods.map(pm =>
                                                <option key={pm.id} value={pm.id}>
                                                    {pm.name}
                                                </option>
                                            )}
                                        </select>
                                    </td>
                                    <td className="deposit-col">
                                        <input
                                            type="checkbox"
                                            className="form-control"
                                            checked={ap.isDeposit || false}
                                            onChange={e => this.updatePayment(ap.id, { isDeposit: e.target.checked })}
                                        />
                                    </td>
                                    <td className="actions-col">

                                        <button type="button" className="btn btn-danger" onClick={() => this.confirmRemovePayment(ap.id)}>
                                            <span className="fa fa-times" />
                                        </button>

                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5} className="description-col">
                                        <ExpandingTextArea
                                            className="form-control"
                                            placeholder="Payment description/notes"
                                            rows={1}
                                            key={ap.description || ''}
                                            defaultValue={ap.description || ''}
                                            onBlur={e => this.updatePayment(ap.id, { description: e.target.value })}
                                        />
                                    </td>
                                </tr>
                            </React.Fragment>
                        )}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>Balance</td>
                            <td className="amount-col">
                                {TextHelpers.formatCurrency(appt.balance)}
                            </td>
                            <td colSpan="3"></td>
                        </tr>
                    </tfoot>
                </table>
            );
        }

        return (
            <div className="form-group">
                <label className="form-label">
                    Payments
                     
                    <button type="button" className="btn btn-primary ms-auto" onClick={() => this.addPayment()}>
                        <span className="fa-solid fa-plus" />{' '}
                        Add Payment
                    </button>
                </label>
                {table}
            </div>
        );
    }

    renderSuggestedAppts() {
        const { appt, suggestedAppts, suggestedApptsStartDate } = this.state;
        if (!appt.property || !appt.duration || appt.status != 'Open') {
            return null;
        }
        let canGoBack = false;
        if (suggestedAppts) {
            canGoBack = !moment().startOf('day').isSameOrAfter(moment(suggestedApptsStartDate));
        }

        return (
            <div className="form-group suggested-appts">
                <label className="form-label">
                    Suggested dates / times
                    ({moment(suggestedApptsStartDate).format('DD/MM/YYYY')} - {moment(suggestedApptsStartDate).add(4 * 7, 'days').format('DD/MM/YYYY')})

                    {suggestedAppts &&
                        <div className="ms-auto">
                            {canGoBack &&
                                <button type="button" className="btn btn-tertiary me-1" onClick={() => this.loadSuggestedAppts(-7 * 4)}>
                                    <span className="fa-solid fa-chevron-left" />{' '}
                                    -1 week
                                </button>
                            }

                            <button type="button" className="btn btn-tertiary" onClick={() => this.loadSuggestedAppts(+7 * 4)}>
                                +1 week{' '}
                                <span className="fa-solid fa-chevron-right" />
                            </button>
                        </div>
                    }

                </label>
                {this.renderSuggestedApptsInner()}
            </div>
        );
    }

    renderSuggestedApptsInner() {
        const { appt, suggestedAppts, suggestedApptSettings, isLoadingSuggestedAppts } = this.state;

        if (!suggestedAppts) {
            return (
                <div>
                    {isLoadingSuggestedAppts ?
                        <Loader /> :
                        <button type="button" className="btn btn-primary" onClick={() => this.loadSuggestedAppts(0)}>
                            <span className="fa-solid fa-lightbulb" />{' '}
                            Get suggestions
                        </button>
                    }
                </div>
            );
        }

        // Put appts into correct bands
        const suggestedApptBands = {
            a: { name: 'Great', suggestedAppts: [] },
            b: { name: 'Good', suggestedAppts: [] },
            c: { name: 'OK', suggestedAppts: [] }
        };
        suggestedAppts.forEach(sa => {
            let band;
            if (sa.cost <= suggestedApptSettings.maxCostBandA) band = 'a';
            else if (sa.cost < suggestedApptSettings.maxCostBandB) band = 'b';
            else band = 'c';
            const suggestedApptBand = suggestedApptBands[band];
            suggestedApptBand.suggestedAppts.push(sa);
        });

        // Sort within the band
        Object.keys(suggestedApptBands).forEach(sab => {
            const suggestedApptBand = suggestedApptBands[sab];
            suggestedApptBand.suggestedAppts.sort((a, b) => a.cost - b.cost);
        });
        
        return (
            <div className="bands">
                {Object.keys(suggestedApptBands).map(sab => {
                    const suggestedApptBand = suggestedApptBands[sab];
                    return (
                        <div className="band" key={sab}>
                            <div className="band-name">
                                {suggestedApptBand.name}
                            </div>
                            {suggestedApptBand.suggestedAppts.length > 0 ?
                                <ul className="appts">
                                    {suggestedApptBand.suggestedAppts.map((suggestedAppt, index) =>
                                        <li
                                            key={index}
                                            className={suggestedAppt.date == appt.date && suggestedAppt.time == appt.time && suggestedAppt.sweep.id == appt.sweepUserID ? 'active' : ''}
                                            style={{
                                                backgroundColor: this.getScaleColour(suggestedAppt.cost, 0, suggestedApptSettings.maxCostBandC),
                                                borderColor: suggestedAppt.sweep.diaryBackColour
                                            }}
                                            onClick={() => this.selectSuggestedAppt(suggestedAppt)}
                                            title={suggestedAppt.notes.join('\r\n')}
                                        >
                                            <div className="appt-inner">
                                                {moment(suggestedAppt.date).format('ddd Do MMM')} <br />
                                                {suggestedAppt.time} with {suggestedAppt.sweep.nickname}
                                            </div>
                                        </li>
                                    )}
                                </ul> :
                                <div className="empty-text">Sorry, couldn't find any {suggestedApptBand.name} appointments</div>
                            }
                        </div>
                    );
                })}      
            </div>
        );
        
        //return (<>
        //    <div className="dates">
        //        {suggestedAppts.map((suggestedAppt, index) => {
        //            const isSlotActive = false;

        //            return (
        //                <div
        //                    key={index}
        //                    className={`slot ${isSlotActive ? 'active' : ''}`}
        //                    style={{ backgroundColor: this.getScaleColour(suggestedAppt.cost, lowestCost, highestCost) }}
        //                    onClick={() => this.selectSuggestedAppt(suggestedAppt)}
        //                    title={suggestedAppt.notes.join('\r\n')}
        //                >
        //                    {suggestedAppt.time}<br />
        //                    {suggestedAppt.sweep.nickname}
        //                </div>
        //            );

        //            //const isDateActive = moment(appt.date).format('YYYY-MM-DD') == moment(date.date).format('YYYY-MM-DD');

        //            //return (
        //            //    <div key={index} className="date">
        //            //        <div
        //            //            className={`date-header ${isDateActive ? 'active' : ''}`}
        //            //            style={{ backgroundColor: this.getScaleColour(date.cost, lowestCostDate, highestCostDate) }}
        //            //            onClick={() => this.selectSuggestedApptDate(date.date)}
        //            //        > 
        //            //            {moment(date.date).format('ddd, DD MMMM')}
        //            //            {/*{' '}({date.cost} / {lowestCostDate} / {highestCostDate})*/}
        //            //        </div>
        //            //        {isDateActive && 
        //            //            <div className="slots">
        //            //                {date.appts.map((slot, index) => {
        //            //                    const isSlotActive = (slot.time == appt.time && slot.sweep.id == appt.sweepUserID);
        //            //                    return (
        //            //                        <div
        //            //                            key={index}
        //            //                            className={`slot ${isSlotActive ? 'active' : ''}`}
        //            //                            style={{ backgroundColor: this.getScaleColour(slot.cost, lowestCost, highestCost) }}
        //            //                            onClick={() => this.selectSuggestedAppt(slot)}
        //            //                            title={slot.notes.join('\r\n')}
        //            //                        >
        //            //                            {slot.time}<br />
        //            //                            {slot.sweep.nickname}
        //            //                            {/*<br />({appt.cost} / {lowestCost} / {highestCost})*/}
        //            //                        </div>
        //            //                    );
        //            //                })}
        //            //            </div>
        //            //        }
        //            //    </div>
        //            //)
        //        })}
        //    </div>

        //</>);
    }

    renderNotifications() {
        const { appt } = this.state;
        const hasSentBookingNotification = appt.notifications.some(n => n.type == 'ApptBooked');

        return (
            <div className="form-group">
                <label className="form-label">
                    Notifications
                </label>
                {appt.notifications.length > 0 ?
                    <ul>
                        {appt.notifications.map((n, index) =>
                            <li key={index}>
                                {ReferenceHelpers.formatNotification(n)}
                            </li>
                        )}
                    </ul> :
                    <p className="form-control-plaintext">No notifications sent yet</p>
                }
                {!hasSentBookingNotification && 
                    this.form.render('skipBookingNotification')    
                }
            </div>
        );
    }

    renderInvoice() {
        const { appt } = this.state;
        if (!appt.invoice || !appt.invoice.number) return null;

        return (
            <div className="form-group">
                <label className="form-label">
                    Invoice

                    {appt.invoice.qbid &&
                        <a className="btn btn-primary ms-auto" href={`https://app.qbo.intuit.com/app/invoice?txnId=${appt.invoice.qbid}`} target="_blank" rel="noreferrer">
                            <span className="fa-solid fa-eye" />{' '}
                            View in QB
                        </a>
                    }
                </label>
                <p className="form-control-plaintext">
                    Invoice number: <strong>{appt.invoice.number}</strong>
                </p>
            </div>
        );
    }

    renderOther() {
        const { appt } = this.state;
        if (!appt.bookedByName && !appt.dateTimeBooked) {
            return null;
        }
        return (
            <div className="form-group">
                <label className="form-label">Other info</label>
                <p className="form-control-plaintext">
                    Booked
                    {!!appt.bookedByName && <> by {appt.bookedByName}</>}
                    {!!appt.bookedByName && <> on {moment(appt.dateTimeBooked).format('DD/MM/YYYY')}</>}
                </p>
            </div>
        );
    }

    renderNonBookingAppt() {
        const { appt } = this.state;

        return (<>

            {this.form.render('notes')}

            {(!appt.id || appt.isRecurring) && this.form.render('isRecurring')}

            {appt.isRecurring && <>

                {this.recurringApptForm.render([
                    'startDate',
                    'endDate'
                ])}

                <div className="row">

                    <div className="col-md-6">

                        {this.recurringApptForm.render('frequencyType')}

                    </div>

                    <div className="col-md-6">

                        {this.recurringApptForm.render('frequency')}

                    </div>

                </div>
            </>}

        </>);
    }
}

export default EditAppt;