import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import AddNewRebookModal from 'components/rebook/AddNewRebookModal';
import { Tooltip } from 'react-tooltip';

//-----------------------------------------------------------------

class ApptList extends React.Component {

    constructor(props) {
        super(props);

        this.addNewRebookModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { customerID, propertyID } = this.props;
        this.setState({ isLoading: true });
        const { rebooks, nextRebookDate } = await API.call('rebook/get-for-customer', { customerID, propertyID })
        this.setState({
            isLoading: false,
            rebooks,
            nextRebookDate
        });
    }

    async add() {
        const { customerID, propertyID } = this.props;
        await this.addNewRebookModalRef.current.open({
            customerID,
            propertyID,
            onSave: this.props.onChange
        });
    }

    async reopen(id) {
        const confirm = window.confirm('Are you sure you want to re-open this rebook?');
        if (!confirm) {
            return;
        }
        this.setState({ isLoading: true });
        await API.call(`rebook/open/${id}`);
        this.setState({ isLoading: false });
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    async close(id) {
        const confirm = window.confirm('Are you sure you want to close this rebook?');
        if (!confirm) {
            return;
        }
        this.setState({ isLoading: true });
        await API.call(`rebook/close/${id}`);
        this.setState({ isLoading: false });
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    async confirmDelete(rebookID) {
        const confirmDeletion = window.confirm('Are you sure you want to delete this rebook?')
        if (!confirmDeletion) {
            return;
        }
        this.setState({ isLoading: true });
        await API.call(`rebook/delete/${rebookID}`, { method: 'DELETE' });
        this.setState({ isLoading: false });
        if (this.props.onChange) {
            this.props.onChange();
        }
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            nextRebookDate
        } = this.state;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <section>
                <Tooltip id="addRebook" />
                
                <h2>
                    <span className="me-auto">
                        Rebooks
                    </span>

                    {nextRebookDate && 
                        <span style={{ fontSize: '14px' }} className="me-3">
                            {' '}Next: {moment(nextRebookDate).format('MMM YYYY')}
                        </span>
                    }

                    <button type="button" className="btn" onClick={() => this.add()}>
                        <span className="fa-solid fa-plus" />{' '}
                        Add Rebook
                    </button>

                </h2>

                {this.renderInner()}
                
                <AddNewRebookModal
                    ref={this.addNewRebookModalRef}
                />

            </section>
        );
    }

    renderInner() {
        const { rebooks } = this.state;
        
        return (

            <SuperTable
                className="table table-bordered"
                rows={rebooks}
                keyAccessor={rebook => rebook.id}
                cols={{
                    date: {
                        label: 'Date',
                        getValue: (colInfo, rebook) => {
                            return moment(rebook.date).format('MMMM YYYY');
                        }
                    },
                    lastCommunicationComment: {
                        label: 'Last comment'
                    },
                    isClosed: {
                        label: 'Status',
                        getValue: (colInfo, rebook) => {
                            return rebook.isClosed ? 'Closed' : 'Open';
                        }
                    },
                    action: {
                        label: 'Action',
                        getValue: (colInfo, rebook) => {
                            return (
                                <div>
                                    <Tooltip id={`openTooltip-${rebook.id}`} />
                                    <Tooltip id={`closeTooltip-${rebook.id}`} />
                                    <Tooltip id={`deleteTooltip-${rebook.id}`} />
                                    <Tooltip id={`addTooltip-${rebook.id}`} />

                                    {rebook.isClosed && (
                                        <i style={{ marginRight: '10px', cursor: 'pointer', fontSize: '20px' }}
                                            className="fa-solid fa-square-arrow-up-right"
                                            onClick={async () => await this.reopen(rebook.id)}
                                            data-tooltip-id={`openTooltip-${rebook.id}`}
                                            data-tooltip-content="Reopen"
                                        ></i>
                                    )}

                                    {!rebook.isClosed && (
                                        <>
                                            <i style={{ marginRight: '10px', cursor: 'pointer', fontSize: '20px' }}
                                                className="fa-solid fa-square-xmark"
                                                data-tooltip-id={`closeTooltip-${rebook.id}`}
                                                data-tooltip-content="Close"
                                                onClick={async () => await this.close(rebook.id)}></i>
                                            <i style={{ cursor: 'pointer', fontSize: '20px' }}
                                                className="fa-solid fa-square-minus"
                                                data-tooltip-id={`deleteTooltip-${rebook.id}`}
                                                data-tooltip-content="Delete"
                                                onClick={async () => await this.confirmDelete(rebook.id)}></i>
                                        </>
                                    )}
                                </div>
                            );
                        }
                    }
                }}
                emptyText="No rebooks added"
            />

        );
    }
}

export default ApptList;