import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';

// Components
import Loader from 'components/common/Loader';
import SuperTable from 'components/common/SuperTable';
import EditApptModal from 'components/appt/EditApptModal';

//-----------------------------------------------------------------

class ApptList extends React.Component {

    constructor(props) {
        super(props);

        this.editApptModalRef = React.createRef();

        this.state = {
            isLoading: true
        };
    }

    componentDidMount() {
        this.load();
    }

    async load() {
        const { customerID, propertyID, oldestFirst } = this.props;
        this.setState({ isLoading: true });
        const appts = await API.call('appt/list', {
            customerID,
            propertyID,
            oldestFirst
        });
        this.setState({
            isLoading: false,
            appts
        });
    }

    add() {
        const { customerID, propertyID } = this.props;
        let url = `/day-sheet?customerID=${customerID}`;
        if (propertyID) {
            url += `&propertyID=${propertyID}`;
        }
        this.props.history.push(url);
    }

    async open(id) {
        if (this.props.disableClick) return;
        await this.editApptModalRef.current.open({
            id
        });
        this.load();
    }

    //---------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading
        } = this.state;
        const { hideButtons } = this.props;

        return (
            <section>

                <h2>
                    Appointments

                    {!isLoading && !hideButtons &&
                        <button type="button" className="btn ms-auto" onClick={() => this.add()}>
                            <span className="fa-solid fa-plus" />{' '}
                            Add Appointment
                        </button>
                    }
                </h2>

                {this.renderInner()}

            </section>
        );
    }

    renderInner() {
        const {
            appts,
            isLoading
        } = this.state;
        const { disableClick } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        const cols = {
            date: {
                label: 'Date',
                type: 'date',
                className: 'date-col'
            },
            time: {
                label: 'Time',
                type: 'time',
                className: 'time-col'
            },
            status: {
                label: 'Status'
            },
            sweepName: {
                label: 'Sweep',
                className: 'sweep-col'
            },
            duration: {
                label: 'Duration',
                className: 'duration-col'
            },
            amountQuoted: {
                label: 'Quoted',
                className: 'amount-quoted-col',
                type: 'currency'
            },
            amountPaid: {
                label: 'Paid',
                className: 'amount-paid-col',
                getValue: (colInfo, appt) => {
                    if (appt.amountQuoted == 0) return '';
                    const balance = appt.amountQuoted - appt.amountPaid;
                    if (balance <= 0) return `Yes (${appt.paymentMethodName})`;
                    else if (appt.paymentType == 'SendInvoice') return 'No (Invoice)';
                    else if (appt.paymentType == 'PaymentToFollow') return 'No (PtF)';
                    else return 'No';
                }
            }
        };

        if (!this.props.hideButtons) {
            cols.actions = {
                className: 'buttons-col-narrow',
                    getValue: (colInfo, a) =>
                        <button className="btn btn-secondary btn-sm" onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            const url = `/day-sheet/?date=${moment(a.date).format('YYYY-MM-DD')}&apptID=${a.id}`;
                            this.props.history.push(url);
                        }}>
                            <span className="fa-solid fa-calendar-day" title="View in day sheet" />
                        </button>
            };
        }

        return (<>
            <div className="sticky-table-container">

                <SuperTable
                    className="appts-table table table-bordered mb-0"
                    rows={appts}
                    keyAccessor={a => a.id}
                    onClick={disableClick ? null : a => this.open(a.id)}
                    cols={cols}
                    emptyText="No appointments for this property"
                />

            </div>

            <EditApptModal
                ref={this.editApptModalRef}
                {...this.props}
            />

        </>);
    }
}

export default ApptList;