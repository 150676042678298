import moment from 'moment';

export default class ReferenceHelpers {

    static CustomerTypes = [
        // Individual - Single property
        { value: 'OwnerOccupier', text: 'Owner / Occupier' },
        { value: 'Tenant', text: 'Tenant' },
        
        // Business - Multiple properties
        { value: 'LettingAgent', text: 'Letting Agent' },
        { value: 'Estate', text: 'Estate' },
        { value: 'HousingAssociation', text: 'Housing Association' },
        { value: 'Landlord', text: 'Landlord' },
        { value: 'Company', text: 'Company' },
        { value: 'MultiplePropertyOwner', text: 'Owner of Multiple Properties' }
    ];
    
    static Months = [
        { value: 1,  text: 'Jan' },
        { value: 2,  text: 'Feb' },
        { value: 3,  text: 'Mar' },
        { value: 4,  text: 'Apr' },
        { value: 5,  text: 'May' },
        { value: 6,  text: 'Jun' },
        { value: 7,  text: 'Jul' },
        { value: 8,  text: 'Aug' },
        { value: 9,  text: 'Sep' },
        { value: 10, text: 'Oct' },
        { value: 11, text: 'Nov' },
        { value: 12, text: 'Dec' },
    ];

    static isIndividual(customerType) {
        return (
            !customerType ||
            customerType == 'OwnerOccupier' ||
            customerType == 'Tenant'
        );
    }
    
    static Floors = [
        { value: 'Ground', label: 'Ground' },
        { value: 'First', label: 'First' },
        { value: 'Second', label: 'Second' },
        { value: 'Basement', label: 'Basement' },
        { value: 'Cellar', label: 'Cellar' },
        { value: 'Lower Ground', label: 'Lower Ground' },
        //{ value: 'Unspecified', label: 'Unspecified' }
    ];
    
    static Locations = [
        { value: 'Living Room', label: 'Living Room' },
        { value: 'Kitchen', label: 'Kitchen' },
        { value: 'Bedroom', label: 'Bedroom' },
        { value: 'Dining Room', label: 'Dining Room' },
        { value: 'Snug', label: 'Snug' },
        { value: 'Study', label: 'Study' },
        { value: 'Office', label: 'Office' },
        { value: 'Drawing Room', label: 'Drawing Room' },
        { value: 'Sitting Room', label: 'Sitting Room' },
        { value: 'Front Room', label: 'Front Room' },
        { value: 'Master Bedroom', label: 'Master Bedroom' },
        { value: 'Bar', label: 'Bar' },
        { value: 'Conservatory', label: 'Conservatory' },
        { value: 'Lobby', label: 'Lobby' },
        //{ value: 'Unspecified', label: 'Unspecified' }
    ];

    static CustomerCommTypes = [
        { id: 'Call', name: 'Call' },
        { id: 'Email', name: 'Email' },
        { id: 'SMS', name: 'SMS' },
        { id: 'Card', name: 'Card' },
        { id: 'Note', name: 'Note' }
    ];

    static OldCustomerCommResults = [
        { id: 'NoAnswer', name: 'No answer (no voicemail)', icon: 'fa-solid fa-times' },
        { id: 'WillCallUs', name: 'Will call us when ready', icon: 'fa-solid fa-times' },
        { id: 'Voicemail', name: 'Left voicemail', icon: 'fa-solid fa-times' },
        { id: 'NotRequired', name: 'Sweep not required this year', icon: 'fa-solid fa-times' },
        { id: 'Rescheduled', name: 'Rescheduled', icon: 'fa-solid fa-times' },
        { id: 'CouldntConnect', name: 'Couldn\'t connect', icon: 'fa-solid fa-times' },
        { id: 'AppointmentBooked', name: 'Appointment booked', icon: 'fa-solid fa-check' }
    ];
    
    static CustomerCommResults = [
        { id: 'AppointmentBooked', name: 'Appointment booked', icon: 'fa-solid fa-check' },
        { id: 'NoChimneyAtProperty', name: 'No chimney at property', icon: 'fa-solid fa-times' },
        { id: 'NoSweepRequiredThisYear', name: 'No sweep required this year', icon: 'fa-solid fa-times' },
        { id: 'CalledNoAnswer', name: 'Called no answer', icon: 'fa-solid fa-times' },
        { id: 'CalledLeftVoiceMail', name: 'Called left voice mail', icon: 'fa-solid fa-times' },
        { id: 'EmailSent', name: 'Email sent', icon: 'fa-solid fa-times' },
        { id: 'CardSent', name: 'Card sent', icon: 'fa-solid fa-times' }
    ];
    
    static OldAndNewCustomerCommResults = [
        { id: 'NoAnswer', name: 'No answer (no voicemail)', icon: 'fa-solid fa-times' },
        { id: 'WillCallUs', name: 'Will call us when ready', icon: 'fa-solid fa-times' },
        { id: 'Voicemail', name: 'Left voicemail', icon: 'fa-solid fa-times' },
        { id: 'NotRequired', name: 'Sweep not required this year', icon: 'fa-solid fa-times' },
        { id: 'Rescheduled', name: 'Rescheduled', icon: 'fa-solid fa-times' },
        { id: 'CouldntConnect', name: 'Couldn\'t connect', icon: 'fa-solid fa-times' },
        { id: 'AppointmentBooked', name: 'Appointment booked', icon: 'fa-solid fa-check' },
        { id: 'NoChimneyAtProperty', name: 'No chimney at property', icon: 'fa-solid fa-times' },
        { id: 'NoSweepRequiredThisYear', name: 'No sweep required this year', icon: 'fa-solid fa-times' },
        { id: 'CalledNoAnswer', name: 'Called no answer', icon: 'fa-solid fa-times' },
        { id: 'CalledLeftVoiceMail', name: 'Called left voice mail', icon: 'fa-solid fa-times' },
        { id: 'EmailSent', name: 'Email sent', icon: 'fa-solid fa-times' },
        { id: 'CardSent', name: 'Card sent', icon: 'fa-solid fa-times' }
    ]

    static CertActions = [
        { id: 'Email', name: 'Email now' },
        { id: 'EmailUponPayment', name: 'Email upon payment' },
        { id: 'SendManually', name: 'Send manually' },
        { id: 'NotNeeded', name: 'Not needed' }
    ];

    static PaymentTypes = [
        { id: 'PayNow', name: 'Pay balance now' },
        { id: 'PaymentToFollow', name: 'Payment to follow' },
        { id: 'SendInvoice', name: 'Send invoice' }
    ];

    static Durations = [
        45, 90, 135, 180, 225, 270, 315, 360, 405, 450, 495, 540, 585, 630, 675, 720, 765, 810, 855, 900, 945, 990, 1035, 1080, 1125, 1170, 1215, 1260, 1305, 1350, 1395, 1440,
        1485, 1530, 1575, 1620, 1665, 1710, 1755, 1800, 1845, 1890, 1935
    ];

    static listTimes() {
        const times = [];
        let hours = 0;
        let minutes = 30;
        while (hours < 24) {
            const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
            const formattedMinutes = minutes === 0 ? '00' : `${minutes}`;
            const time = `${formattedHours}:${formattedMinutes}`;
            times.push(time);
            minutes += 45;
            if (minutes >= 60) {
                minutes -= 60;
                hours++;
            }
        }
        return times;
    }

    static formatNotification(notification) {
        let msg = moment(notification.dateTime).format('DD/MM/YYYY');
        if (msg == '01/01/2000') {
            msg = 'Old system';
        }
        msg += ': ';
        switch (notification.type) {
            case 'ApptBooked':
                msg += `Booked`;
                break;
            case 'ApptCompleted':
                msg += `Completed`;
                break;
            case 'ApptReminder':
                msg += `Reminder, `;
                msg += (notification.numDays == 1 ? '1 day' : notification.numDays + ' days') + ' ahead';
                break;
        } 
        if (notification.customerComms.length > 0) {
            msg += ` (${notification.customerComms.map(cc => cc.type).join(', ')})`;
        }
        return msg;
    };

    static getTravelTimeColour(travelTime, settings) {
        if (!travelTime || !settings.travelTimeGreen || !settings.travelTimeRed) {
            return '#FF00FF'; // Magenta - not set up properly 
        }
        if (travelTime <= settings.travelTimeGreen) {
            return '#00C800'; // Green
        }
        else if (travelTime < settings.travelTimeRed) {
            return '#ff8000'; // Orange
        } else {
            return '#C80000'; // Red
        }
    }

}