// Libs
import React, { Component } from 'react';
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    Polyline,
    OverlayView
} from 'react-google-maps';
import moment from 'moment';

// Services & Helpers
import DateHelpers from 'helpers/DateHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

//-------------------------------------------------------------------------------------------------------------------

class Map extends Component {

    constructor(props) {
        super(props);

    }

    componentDidUpdate(oldProps) {
        if (this.props.date && this.props.date != oldProps.date) {
            this.debounceSetBounds();
        }
        if (this.props.isRescheduling != oldProps.isRescheduling || this.props.activeAppt != oldProps.activeAppt) {
            this.debounceSetBounds();
        }
    }

    debounceSetBounds(timeoutMS) {
        if (typeof (timeoutMS) == 'undefined') {
            timeoutMS = 500;
        }
        window.clearTimeout(this.setBoundsTimeout);
        this.setBoundsTimeout = window.setTimeout(() => {
            if (this.props.areApptsLoading) {
                this.debounceSetBounds(100);
            } else {
                this.setBounds();
            }
        }, timeoutMS);
    }

    setBounds() {
        const { loginInfo, activeAppt } = this.props;
        if (!this.map) return;
        const bounds = new window.google.maps.LatLngBounds();
        let anyAdded = false;
        const { apptsBySweep, sweepsByID } = this.getLookups();

        // Add star icon into the bounds
        if (activeAppt && activeAppt.property && activeAppt.property.address && activeAppt.property.address.longitude) {
            bounds.extend(new window.google.maps.LatLng(
                activeAppt.property.address.latitude,
                activeAppt.property.address.longitude
            ));
        }

        Object.keys(apptsBySweep).forEach(sweepUserID => {
            const sweep = sweepsByID[sweepUserID];
            const appts = apptsBySweep[sweepUserID];
            if (!sweep || !appts) return;
                if (sweep.baseAddress && sweep.baseAddress.longitude) {
                bounds.extend(new window.google.maps.LatLng(
                    sweep.baseAddress.latitude,
                    sweep.baseAddress.longitude
                ));
                anyAdded = true;
            }
            appts.forEach(appt => {
                if (appt.property && appt.property.address.longitude) {
                    bounds.extend(new window.google.maps.LatLng(
                        appt.property.address.latitude,
                        appt.property.address.longitude
                    ));
                    anyAdded = true;
                }
            });
        });
        if (!anyAdded && loginInfo.account.tradingAddress && loginInfo.account.tradingAddress.longitude) {
            bounds.extend(new window.google.maps.LatLng(
                loginInfo.account.tradingAddress.latitude,
                loginInfo.account.tradingAddress.longitude
            ));
            anyAdded = true;
        }
        if (anyAdded) {
            this.map.fitBounds(bounds);
        }

        // Zoom into a specific appt
        if (activeAppt && activeAppt.id && this.currentApptID != activeAppt.id) {
            this.currentApptID = activeAppt.id;
            if (activeAppt.property && activeAppt.property.address && activeAppt.property.address.longitude) {
                const apptCoords = new window.google.maps.LatLng(
                    activeAppt.property.address.latitude,
                    activeAppt.property.address.longitude
                );
                this.map.panTo(apptCoords);
            }
        }
    }

    getLookups(appts) {
        if (!appts) {
            appts = this.props.appts;
        }
        const { sweeps } = this.props;
        const sweepsByID = {};
        sweeps.forEach(s => sweepsByID[s.id] = s);
        const apptsBySweep = {};
        const allAppts = [];
        appts.forEach((appt, index) => {
            if (appt.property && appt.property.address && appt.property.address.longitude) {
                allAppts.push(appt);
                if (!apptsBySweep[appt.sweepUserID]) {
                    apptsBySweep[appt.sweepUserID] = [];
                }
                apptsBySweep[appt.sweepUserID].push({
                    ...appt,
                    num: (index + 1)
                });
            }
        });
        sweeps.forEach(sweep => {
            if (!apptsBySweep[sweep.id]) {
                apptsBySweep[sweep.id] = [];
            }
        });
        return { allAppts, apptsBySweep, sweepsByID };
    }

    render() {
        const {
            activeAppt,
            view,
            isRescheduling
        } = this.props;
        const appts = [...this.props.appts];

        // Add a dummy appointment
        if (activeAppt && (!activeAppt.id || isRescheduling) && activeAppt.property && activeAppt.time) {
            appts.push(activeAppt);
            DateHelpers.sortApptList(appts);

            // If rescheduling, remove the original appt as it will be on the same day and therefore duplicated
            if (isRescheduling) {
                const thisApptIndex = appts.find(a => a.id == activeAppt.id);
                if (thisApptIndex != -1) {
                    appts.splice(thisApptIndex, 1);
                }
            }
        }

        // Get lookups
        const { apptsBySweep, sweepsByID } = this.getLookups(appts);

        // Render
        return (
            <GoogleMap
                defaultZoom={8}
                defaultOption={{
                    styles: this.getStyles()
                }}
                options={{ maxZoom: 17 }}
                ref={map => {
                    this.map = map;
                    this.debounceSetBounds();
                }}
            >
                {activeAppt && activeAppt.property && activeAppt.property.address && activeAppt.property.address.longitude && (!activeAppt.time || !activeAppt.sweepUserID || isRescheduling) &&
                    <Marker
                        position={{
                            lat: activeAppt.property.address.latitude,
                            lng: activeAppt.property.address.longitude
                        }}
                        icon={Map.getMarkerIcon({ type: 'star' })}
                        optimized={false}
                        zIndex={10000}
                    / >
                }

                {Object.keys(apptsBySweep).map(sweepUserID => {
                    const sweep = sweepsByID[sweepUserID];
                    const appts = apptsBySweep[sweepUserID];
                    if (!sweep || !appts) return null;

                    return (<React.Fragment key={sweepUserID}>

                        {/* Home address */}
                        {view == 'day' && sweep.baseAddress &&
                            this.renderMarkerAndLine({
                                property: { address: sweep.baseAddress },
                                sweep
                            }, (appts.length > 0 ? appts[0] : null), 'H', sweepUserID)
                        }

                        {/* Appointments */}
                        {appts.map((appt, index) => {
                            let nextAppt = (index < appts.length - 1 ? appts[index + 1] : null);

                            // Back home
                            if (view == 'day' && !nextAppt && sweep.baseAddress) {
                                nextAppt = {
                                    property: { address: sweep.baseAddress },
                                    sweep
                                };
                            }
                            let label;
                            if (view == 'day') {
                                label = `${index + 1}`;
                            } else {
                                label = moment(appt.date).format('dd');
                            }

                            return (
                                <React.Fragment key={appt.id || 'new'}>
                                    {this.renderMarkerAndLine(
                                        appt,
                                        nextAppt,
                                        label,
                                        sweepUserID
                                    )}
                                </React.Fragment>
                            )
                        })}

                        {/* If no appointments, just show home address */}
                        {view == 'day' && appts.length == 0 && sweep.baseAddress && 
                            this.renderMarkerAndLine({
                                property: { address: sweep.baseAddress },
                                sweep
                            }, null, 'H', sweepUserID)
                        }

                    </React.Fragment>);
                })}
            </GoogleMap>
        );
    }

    renderMarkerAndLine(appt, nextAppt, label) {
        const {
            activeAppt,
            onClick,
            travelStats,
            view,
            hideLines
        } = this.props;

        // Get line colour
        let travelTime = 0;
        if (travelStats && nextAppt) {
            const travelStatKey = `${appt.property.address.postcode}-${nextAppt.property.address.postcode}`;
            const travelStat = travelStats[travelStatKey];
            if (travelStat) {
                travelTime = travelStat.travelTime;
            }
        }
        const travelLineColour = ReferenceHelpers.getTravelTimeColour(travelTime, this.props.loginInfo.account);
        const isStar = activeAppt && (
            (appt.id && activeAppt.id && appt.id == activeAppt.id) ||
            (appt.time && appt.time == activeAppt.time && appt.sweepUserID && appt.sweepUserID == activeAppt.sweepUserID)
        );
        const icon = Map.getMarkerIcon({
            fillColor: appt.sweep.diaryBackColour,
            strokeColor: (activeAppt && activeAppt.id && activeAppt.id == appt.id ? '#FF00FF' : appt.sweep.diaryTextColour),
            type: (isStar ? 'star' : 'circle')
        });
        
        return (<>

            <Marker
                position={{
                    lat: appt.property.address.latitude,
                    lng: appt.property.address.longitude
                }}
                onClick={e => {
                    if (appt.id) {
                        onClick(appt.id);
                    }
                }}
                icon={icon}
                label={label}
                labelAnchor={{ x: 50, y: -50 }}
                optimized={false}
                zIndex={isStar ? 10000 : 0}
            />
            {view == 'day' && nextAppt && !hideLines && <>
                <Polyline
                    path={[
                        { lat: appt.property.address.latitude, lng: appt.property.address.longitude },
                        { lat: nextAppt.property.address.latitude, lng: nextAppt.property.address.longitude }
                    ]}
                    geodesic
                    options={{
                        strokeColor: travelLineColour,
                        strokeWeight: 4,
                        //icons: [{
                        //    icon: {
                        //        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                        //        fillColor: travelLineColour,
                        //        fillOpacity: 1,
                        //        strokeWeight: 0,
                        //        zIndex: 0,
                        //        optimized: false
                        //    },
                        //    offset: '40px',
                        //    repeat: '50px'
                        //}]
                    }}
                    optimized={false}
                    zIndex={0}
                />
                {/*
                {travelTime >= 5 &&
                    <OverlayView
                        position={{
                            lat: (nextAppt.property.address.latitude + appt.property.address.latitude) / 2,
                            lng: (nextAppt.property.address.longitude + appt.property.address.longitude) / 2
                        }}
                        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                        optimized={false}
                        zIndex={0}
                    >
                        <div style={{
                                background: `white`,
                                padding: 2,
                                width: 24,
                                height: 24,
                                marginLeft: -12,
                                marginTop: -12,
                                textAlign: 'center',
                                border: '2px solid ' + travelLineColour,
                                borderRadius: '100%',
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                pointerEvents: 'none',
                                opacity: 0
                            }}
                        >
                            {travelTime}
                        </div>
                    </OverlayView>
                }
                */}
            </>}
        </>);
    }

    getStyles() {
        return [
            {
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#f5f5f5'
                    }
                ]
            },
            {
                elementType: 'labels.icon',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#616161'
                    }
                ]
            },
            {
                elementType: 'labels.text.stroke',
                stylers: [
                    {
                        'color': '#f5f5f5'
                    }
                ]
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                featureType: 'administrative.land_parcel',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#bdbdbd'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                featureType: 'poi',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#757575'
                    }
                ]
            },
            {
                featureType: 'poi.business',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#e5e5e5'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                featureType: 'poi.park',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#9e9e9e'
                    }
                ]
            },
            {
                featureType: 'road',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#ffffff'
                    }
                ]
            },
            {
                featureType: 'road.arterial',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#757575'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#dadada'
                    }
                ]
            },
            {
                featureType: 'road.highway',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#616161'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'labels',
                stylers: [
                    {
                        'visibility': 'off'
                    }
                ]
            },
            {
                featureType: 'road.local',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#9e9e9e'
                    }
                ]
            },
            {
                featureType: 'transit.line',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#e5e5e5'
                    }
                ]
            },
            {
                featureType: 'transit.station',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#eeeeee'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'geometry',
                stylers: [
                    {
                        'color': '#c9c9c9'
                    }
                ]
            },
            {
                featureType: 'water',
                elementType: 'labels.text.fill',
                stylers: [
                    {
                        'color': '#9e9e9e'
                    }
                ]
            }
        ];
    }

    static getMarkerIcon(opts) {
        let path;
        if (opts.type == 'circle') {
            path = `
                M 0, 0
                m -1, 0
                a 1, 1 0 1, 0 2, 0
                a 1, 1 0 1, 0 -2, 0
            `;
        } else if (opts.type == 'star') {
            path = `
                M -0.7898 1.2497 
                c -0.0695 0.0356 -0.1483 -0.0268 -0.1343 -0.1066 
                l 0.1494 -0.8514 
                L -1.4089 -0.3123 
                c -0.0592 -0.0565 -0.0284 -0.1598 0.0509 -0.171 
                l 0.8816 -0.1253 
                L -0.0832 -1.3874 
                c 0.0355 -0.0702 0.1314 -0.0702 0.1669 0 
                l 0.3931 0.7789 
                l 0.8816 0.1253 
                c 0.0794 0.0112 0.1102 0.1145 0.0508 0.171
                l -0.634 0.6041 
                l 0.1494 0.8514 
                c 0.014 0.0797 -0.0648 0.1422 -0.1343 0.1066 
                L 0 0.8437 
                l -0.79 0.4061
                z
            `;
            if (!opts.fillColor) {
                opts.fillColor = '#FFFF00';
                opts.strokeColor = '#808000';
            }
        }
        return {
            path,
            fillOpacity: 1,
            strokeWeight: 2,
            scale: 13,
            ...opts
        };
    }

}

export default withScriptjs(withGoogleMap(Map));