import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { NumericFormat } from 'react-number-format';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditServiceTypeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text',
                    isReadOnly: () => this.state.serviceType.isStandard
                },
                code: {
                    label: 'Code',
                    type: 'text',
                    isReadOnly: () => this.state.serviceType.isStandard
                },
                verb: {
                    label: 'Verb (I am visiting your house to ...)',
                    type: 'text',
                    isReadOnly: () => this.state.serviceType.isStandard
                },
                isEnabled: {
                    boxLabel: 'Enabled',
                    type: 'checkbox',
                    isReadOnly: () => this.state.serviceType.isMandatory
                },
                sendApptConfirmationEmail: {
                    boxLabel: 'Send appointment confirmation email/SMS',
                    type: 'checkbox',
                    isReadOnly: () => this.state.serviceType.isStandard
                },
                sendApptCompletedEmail: {
                    boxLabel: 'Send appointment completed email/SMS',
                    type: 'checkbox',
                    isReadOnly: () => this.state.serviceType.isStandard
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const { id } = this.state;

        // Load service type
        let serviceType;
        if (id) {
            serviceType = await API.call('service-type/get/' + id);
        } else {
            serviceType = {
                isEnabled: true,
                isMandatory: true,
                durations: [0,0,0,0,0,0,0,0,0,0]
            }
        }

        // Load related entities
        const priceSchemes = await API.call('price-scheme/list', {
            serviceTypeID: id
        });
        if (!id) {
            priceSchemes.forEach(ps => {
                ps.firstPrice = 0;
                ps.secondPlusPrice = 0;
            });
        }

        // Update
        this.setState({
            serviceType,
            priceSchemes,
            isLoading: false
        }, () => {
            this.tryLoadQuickbooksInfo();
        });
    }

    async tryLoadQuickbooksInfo() {
        if (
            this.state.serviceType.isEnabled &&
            !this.state.quickbooksItems
        ) {
            const isQuickbooksConnected = await API.call('quickbooks/get-is-connected');
            this.setState({ isQuickbooksConnected });
            if (isQuickbooksConnected) {
                const quickbooksItems = await API.call('quickbooks/list-items');
                this.setState({ quickbooksItems });
            }
        }
    }
    
    getFieldValue(fieldName) {
        return this.state.serviceType[fieldName];
    }

    updateFields(fields) {
        const serviceType = { ...this.state.serviceType };
        let loadQuickbooksInfo = false;
        for (let fieldName in fields) {
            const value = fields[fieldName];
            serviceType[fieldName] = value;
            if (fieldName == 'isEnabled' && value) {
                loadQuickbooksInfo = true;
            }
        }
        this.setState({
            serviceType
        }, () => {
            if (loadQuickbooksInfo) {
                this.tryLoadQuickbooksInfo();
            }
        });
    }

    updatePriceSchemeFields(priceSchemeID, fields) {
        const priceSchemes = [...this.state.priceSchemes];
        const index = priceSchemes.findIndex(st => st.id == priceSchemeID);
        const priceScheme = { ...priceSchemes[index] };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            priceScheme[fieldName] = value;
        }
        priceSchemes[index] = priceScheme;
        this.setState({ priceSchemes });
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        const serviceType = {
            ...this.state.serviceType,
            priceSchemes: this.state.priceSchemes.map(ps => ({
                priceSchemeID: ps.id,
                ...ps
            }))
        };
        await API.call('service-type/save', serviceType);
        this.close();
        this.resolve();
    }

    updateDuration(num, duration) {
        const durations = [...this.state.serviceType.durations];
        durations[num] = (parseInt(duration) || 0);
        this.updateFields({ durations });
    }

    render() {
        const { 
            isOpen,
            isLoading,
            serviceType
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-service-type-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        const { priceSchemes, serviceType, isQuickbooksConnected, quickbooksItems } = this.state;
        
        return (<>

            {this.form.render([
                (!!serviceType.id && 'isEnabled'),
                'name',
                'code',
                'verb',
                'sendApptConfirmationEmail',
                'sendApptCompletedEmail'
            ])}

            {serviceType.isEnabled && <>
                <table className="table table-bordered service-type-price-schemes-table">
                    <thead>
                        <tr>
                            <th>Price Scheme</th>
                            <th className="price-col">1st Price</th>
                            <th className="price-col">2nd+ Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {priceSchemes.map(priceScheme =>
                            <tr key={priceScheme.id}>
                                <td>{priceScheme.name}</td>
                                <td className="price-col">
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        prefix="£"
                                        value={priceScheme.firstPrice || ''}
                                        onValueChange={(values) => {
                                            const value = Number(values.value) || '';
                                            this.updatePriceSchemeFields(priceScheme.id, { firstPrice: value });
                                        }}
                                    />
                                </td>
                                <td className="price-col">
                                    <NumericFormat
                                        className="form-control"
                                        thousandSeparator={','}
                                        decimalSeparator={'.'}
                                        prefix="£"
                                        value={priceScheme.secondPlusPrice || ''}
                                        onValueChange={(values) => {
                                            const value = Number(values.value) || '';
                                            this.updatePriceSchemeFields(priceScheme.id, { secondPlusPrice: value });
                                        }}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

                <div className="form-group">
                    <label className="form-label">Duration</label>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Number</th>
                                <th>Duration</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceType.durations.map((duration, index) =>
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                        <input
                                            type="number"
                                            className="form-control"
                                            value={duration || ''}
                                            onChange={e => this.updateDuration(index, e.target.value)}
                                        />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {isQuickbooksConnected &&
                    <div className="form-group mb-0">
                        <label className="form-label">QuickBooks Item/Service</label>
                        {quickbooksItems ?
                            <select
                                className="form-control"
                                value={serviceType.qbItemID || ''}
                                onChange={e => this.updateFields({ qbItemID: e.target.value })}
                            >
                                <option value="">(None)</option>
                                {quickbooksItems.map(qbi =>
                                    <option key={qbi.id} value={qbi.id}>
                                        {qbi.name}
                                    </option>
                                )}
                            </select> :
                            <Loader />
                        }
                    </div>
                }
            </>}
        </>);
    }
}

export default EditServiceTypeModal;