import React from 'react';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import TextHelpers from 'helpers/TextHelpers';

// Components
import SuperTable from 'components/common/SuperTable';
import Loader from 'components/common/Loader';
import EditCustomerModal from 'components/customer/EditCustomerModal';
//import EditRebookModal from 'components/rebook/EditRebookModal';

//-----------------------------------------------------------------

class ListRebooks extends React.Component {

    constructor(props) {
        super(props);

        this.editCustomerModalRef = React.createRef();

        this.state = {
            isLoadingMonths: true,
            sortBy: '',
            rebooks: [],
            months: [],
            includeClosed: false
        };
    }
    
    componentDidMount() {
        this.props.fns.setTitle('Rebooks');
        this.loadMonths();
        this.loadMailchimp();
    }

    componentDidUpdate(oldProps) {
        if (oldProps.month && !this.props.month) {
            this.loadMonths();
        } else if (this.props.month != oldProps.month) {
            this.load(false);
        }
    }

    async loadMonths() {
        this.setState({ isLoadingMonths: true });
        const months = await API.call('rebook/list-months');

        // Update UI
        this.setState({
            isLoadingMonths: false,
            months
        });

        // Load first month
        if (this.props.month) {
            this.load(false);
        } else {

            // Get previously selected month
            let month;
            try {
                month = localStorage.getItem('rebooks-month');
            } catch (e) {
                // Ignore
            }

            // Check that this month is actually in the list
            if (month) {
                const monthIndex = months.findIndex(m => moment(m).format('YYYY-MM-DD') == month);
                if (monthIndex == -1) {
                    month = null;
                }
            }

            // Default to this month if not set
            if (!month) {
                month = moment().format('YYYY-MM-01');

                // If still not found, just default to the first in the list
                const monthIndex = months.findIndex(m => moment(m).format('YYYY-MM-DD') == month);
                if (monthIndex == -1) {
                    month = moment(months[0]).format('YYYY-MM-01');
                }
            }

            // Save
            localStorage.setItem('rebooks-month', month);
            this.props.history.replace(`/rebooks/${month}`);
        }
    }

    async load(isSilent) {
        const { month } = this.props;
        localStorage.setItem('rebooks-month', month);
        if (!isSilent) {
            this.setState({ isLoading: true });
        }
        let rebooks = await API.call('rebook/list/' + month);
        
        this.setState({
            isLoading: false,
            rebooks
        });
    }

    async loadMailchimp() {
        const isMailchimpConnected = await API.call('mailchimp/get-is-connected');
        this.setState({ isMailchimpConnected });
    }

    async open(rebook) {
        await this.editCustomerModalRef.current.open({
            id: rebook.customer.id,
            propertyID: rebook.property.id,
            cameFrom: 'rebooks'
        });
        this.load(true);
    }

    confirmSendToMailchimp() {
        const confirm = window.confirm('Send this rebook list to Mailchimp?');
        if (confirm) {
            this.sendToMailchimp();
        }
    }

    async sendToMailchimp() {
        const { month } = this.props;
        this.setState({ isLoading: true });
        const { numSynchronised, tagName, dataCentre } = await API.call('mailchimp/synchronise-rebook-list/' + month);
        this.setState({
            isLoading: false
        }, () => {
            alert(`${numSynchronised} contact(s) synchronised using tag: '${tagName}'`);
            window.open(`https://${dataCentre}.admin.mailchimp.com/campaigns/#t:campaigns-list`);
        });
    }

    exportToExcel() {
        API.call('rebook/export-excel/' + this.props.month, {
            isDownload: true,
            includeClosed: this.state.includeClosed
        });
    }

    confirmMarkAllAsSentCard() {
        const confirm = window.confirm('Mark all rebooks as \'Sent Card\' and remove them from this list?');
        if (confirm) {
            this.markAllAsSentCard();
        }
    }

    async markAllAsSentCard() {
        this.setState({ isLoading: true });
        await API.call('rebook/mark-sent-card/' + this.props.month);
        this.props.history.replace('/rebooks');
    }

    //----------------------------------------------------------------------

    render() {
        const {
            isLoading,
            isLoadingMonths,
            isMailchimpConnected,
            rebooks
        } = this.state;
        const { month } = this.props;
        const months = [...this.state.months];
        
        if (isLoadingMonths) {
            return (<Loader />);
        }

        return (<>

            <section className="control-panel rebooks-page">

                <select
                    className="form-control month-selector"
                    value={month || ''}
                    onChange={e => this.props.history.replace(`/rebooks/${e.target.value}`)}
                >
                    {months.map(month =>
                        <option key={moment(month).format('YYYY-MM')} value={moment(month).format('YYYY-MM-01')}>
                            {moment(month).format('MMMM YYYY')}
                        </option>
                    )}
                </select>

                {!isLoading && month && <>
                    <div className="control-panel-component ms-3">
                        Number of items: {this.state.includeClosed ? rebooks.length : rebooks.filter(r => !r.isClosed).length}
                    </div>
                    <div className="control-panel-component ms-3">
                        <input 
                            type="checkbox" 
                            id="showClosed" 
                            style={{ marginRight: '0.5rem'}} 
                            checked={this.state.includeClosed} 
                            onChange={(value) => this.setState({ includeClosed: value.target.checked })} />
                        <label htmlFor="showClosed">Show closed</label>
                    </div>
                    <div className="ms-auto">
                        {isMailchimpConnected &&
                            <button type="button" className="btn btn-secondary" onClick={() => this.confirmSendToMailchimp()}>
                                Send to Mailchimp
                            </button>
                        }
                        <button type="button" className="btn btn-secondary" onClick={() => this.exportToExcel()}>
                            Export to Excel
                        </button>

                        <div className="dropdown ms-3">
                            <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                More...
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a className="dropdown-item" href="#" onClick={e => {
                                        e.preventDefault();
                                        this.confirmMarkAllAsSentCard();
                                    }}>
                                        <span className="fa-solid fa-address-card" />{' '}
                                        Mark all as 'Sent Card'
                                    </a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </>}

            </section>

            <section>

                {this.renderTable()}

            </section>

            {/*
            <EditRebookModal
                ref={this.editRebookModalRef}
                {...this.props}
            />
            */}

            <EditCustomerModal
                {...this.props}
                ref={this.editCustomerModalRef}
            />

        </>);
    }

    renderTable() {
        const {
            isLoading,
            rebooks,
            sortBy,
            sortDesc
        } = this.state;
        const { month } = this.props;

        if (isLoading) {
            return (<Loader />);
        }

        return (
            <SuperTable
                className="rebooks-table table table-bordered clickable mb-0"
                rows={this.state.includeClosed ? rebooks : rebooks.filter(r => !r.isClosed)}
                keyAccessor={rebook => rebook.id}
                sortBy={sortBy}
                sortDesc={sortDesc}
                onChangeSort={(sortBy, sortDesc) => this.setState({ sortBy, sortDesc })}
                cols={{
                    customerName: {
                        label: 'Customer',
                        sortable: true,
                        sort: (a, b) => {
                            const getVal = (rebook) => rebook.customer.lastName + ', ' + rebook.customer.firstName;
                            const valA = getVal(a);
                            const valB = getVal(b);
                            if (valA > valB) return 1;
                            else if (valA < valB) return -1;
                            else return 0;
                        },
                        getValue: (colInfo, rebook) => {
                            const lines = [
                                TextHelpers.formatName(
                                    rebook.customer.lastName ? rebook.customer.lastName + ', ' : '',
                                    rebook.customer.title,
                                    rebook.customer.firstName
                                ),
                                rebook.customer.companyName ? 'Company: ' + rebook.customer.companyName : ''
                            ];
                            return lines.filter(l => !!l).map((l, index) =>
                                <div key={index}>
                                    <div>{l}</div>
                                    <div>Properties: {rebook.customerPropertiesCount}</div>
                                </div>
                            );
                        }
                    },
                    propertyName: {
                        label: 'Property',
                        sortable: true,
                        sort: (a, b) => {
                            const getVal = (rebook) => rebook.property.address.postcode;
                            const valA = getVal(a);
                            const valB = getVal(b);
                            if (valA > valB) return 1;
                            else if (valA < valB) return -1;
                            else return 0;
                        },
                        getValue: (colInfo, rebook) => {
                            const lines = [
                                rebook.property.address.line1,
                                [
                                    rebook.property.address.line2,
                                    rebook.property.address.town,
                                    rebook.property.address.postcode
                                ].filter(l => !!l).join(', ')
                            ];
                            return (<div>

                                <div className="num-chimneys" title={`${rebook.property.numChimneys} chimney(s)`}>
                                    <span className="fa-solid fa-house-chimney" />{' '}
                                    {rebook.property.numChimneys}
                                </div>

                                {lines.filter(l => !!l).map((l, index) =>
                                    <div key={index} style={{ width: 'auto'}}>
                                        {l}
                                    </div>
                                )}
                                
                                <a href={null} onClick={(e) => {
                                    this.props.history.push(`/day-sheet?customerID=${rebook.customer.id}`);
                                    e.stopPropagation();
                                    
                                }} style={{ color: "#0066CC", textDecoration: "underline", zIndex: "999" }}>New Appointment</a>
                            </div>);
                        }
                    },
                    lastAppt: {
                        label: 'Last Appt',
                        sortable: true,
                        sort: (a, b) => {
                            const aVal = a.lastApptDate ? parseInt(moment(a.lastApptDate).format('YYYYMMDD')) : 999999999999;
                            const bVal = b.lastApptDate ? parseInt(moment(b.lastApptDate).format('YYYYMMDD')) : 999999999999;
                            return aVal - bVal;
                        },
                        getValue: (colInfo, rebook) => {
                            const lines = [
                                rebook.lastApptDate ? moment(rebook.lastApptDate).format('DD/MM/YYYY') : '',
                                rebook.lastApptSweep ? rebook.lastApptSweep.nickname : null
                            ]
                            return lines.map((l, index) => (
                                <div key={index}>{l}</div>
                            ));
                        }
                    },
                    call1: {
                        label: 'Call 1',
                        sortable: true,
                        sort: (a, b) => {
                            let aDate = null;
                            let bDate = null;
                            
                            aDate = a.lastCommunications[0].date;
                            
                            bDate = b.lastCommunications[0].date;
                            
                            const aVal = aDate ? parseInt(moment(aDate).format('YYYYMMDD')) : 999999999999;
                            const bVal = bDate ? parseInt(moment(bDate).format('YYYYMMDD')) : 999999999999;
                            return aVal - bVal;
                        },
                        getValue: (colInfo, rebook) => {
                            if (rebook.lastCommunications.length === 0) {
                                return null;
                            }

                            let communication = rebook.lastCommunications[0];
                            
                            const lines = [
                                (communication.date ? moment(communication.date).format('DD/MM/YYYY') : ''),
                                (communication.result || '') + ' ' + (communication.result && communication.description ? ': ' : '') + (communication.description ? `${communication.description}` : '')
                            ];
                            return lines.filter(l => !!l).map((l, index) => (
                                <div key={index} style={{ maxWidth: "250px"}}>{l}</div>
                            ));
                        }
                    },
                    call2: {
                        label: 'Call 2',
                        sortable: true,
                        sort: (a, b) => {
                            let aDate = a.lastCommunications.length > 1 ? a.lastCommunications[1].date : null;
                            let bDate = b.lastCommunications.length > 1 ? b.lastCommunications[1].date : null;
                            
                            const aVal = aDate ? parseInt(moment(aDate).format('YYYYMMDD')) : 999999999999;
                            const bVal = bDate ? parseInt(moment(bDate).format('YYYYMMDD')) : 999999999999;
                            return aVal - bVal;
                        },
                        getValue: (colInfo, rebook) => {
                            if (rebook.lastCommunications.length < 2) {
                                return null;
                            }

                            let communication = rebook.lastCommunications[1];

                            const lines = [
                                (communication.date ? moment(communication.date).format('DD/MM/YYYY') : ''),
                                (communication.result || '') + ' ' + (communication.result && communication.description ? ': ' : '') + (communication.description ? `${communication.description}` : '')
                            ];
                            return lines.filter(l => !!l).map((l, index) => (
                                <div key={index} style={{ maxWidth: "250px"}}>{l}</div>
                            ));
                        }
                    },
                    call3: {
                        label: 'Call 3',
                        sortable: true,
                        sort: (a, b) => {
                            let aDate = a.lastCommunications.length > 2 ? a.lastCommunications[2].date : null;
                            let bDate = b.lastCommunications.length > 2 ? b.lastCommunications[2].date : null;
                            
                            const aVal = aDate ? parseInt(moment(aDate).format('YYYYMMDD')) : 999999999999;
                            const bVal = bDate ? parseInt(moment(bDate).format('YYYYMMDD')) : 999999999999;
                            return aVal - bVal;
                        },
                        getValue: (colInfo, rebook) => {
                            if (rebook.lastCommunications.length < 3) {
                                return null;
                            }

                            let communication = rebook.lastCommunications[2];

                            const lines = [
                                (communication.date ? moment(communication.date).format('DD/MM/YYYY') : ''),
                                (communication.result || '') + ' ' + (communication.result && communication.description ? ': ' : '') + (communication.description ? `${communication.description}` : '')
                            ];
                            return lines.filter(l => !!l).map((l, index) => (
                                <div key={index} style={{ maxWidth: "250px"}}>{l}</div>
                            ));
                        }
                    },
                    status: {
                        label: 'Status',
                        getValue: (colInfo, rebook) => {
                            let columnValue = rebook.isClosed ? 'Closed' : 'Open';
                            
                            return <div>{columnValue}</div>;
                        }
                    }
                }}
                onClick={(rebook, e) => this.open(rebook)}
                emptyText={month ? 'No rebooks to show' : 'Please select a month'}
            />
        );
    }
}

export default ListRebooks;