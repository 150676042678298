import React from 'react';

export default class TextHelpers {

    static DaysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

    static formatName(part1, part2, part3) {
        var nameParts = [part1, part2, part3].filter(np => !!((np || '').trim())).map(np => (np || ''));
        return nameParts.join(' ') || '';
    }
   
    static simplifySearchString(str) {
        return (str || '').toLowerCase().replace(/[^a-z0-9 -'&@]/, '').trim();
    }

    static formatPercentage(percentage, numDP) {
        if (typeof (numDP) == 'undefined') {
            numDP = 2;
        }
        const div = Math.pow(10, numDP);
        let value = Math.round(percentage * 100 * div) / div;
        return value;
    }

    static formatCurrency(amount, opt) {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'GBP',
            //minimumFractionDigits: 0,
            //maximumFractionDigits: 2,
            ...opt
        });
        let str = formatter.format(amount);
        str = str.replace('.00', '');
        return str;
    }

    // https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
    static getRandomGUID() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    static formatHighlightedText(str) {
        const parts = str.split('*');
        const replaced = parts.map((part, i) => {
            if (i % 2 === 0) {
                return part;
            } else {
                return <span key={i} className="search-highlight">{part}</span>;
            }
        });
        return replaced;
    }

    static getFriendlyName(type, value, emptyText) {
        value = (value || '').toLowerCase();
        if (!value) {
            return emptyText || '';
        }

        switch (type) {
            case 'SearchResult':
                switch (value) {
                    case 'customer': return 'Customer';
                    case 'appt': return 'Appt';
                    default: return value;
                }
            case 'PaymentMethod':
                switch (value) {
                    case 'cash': return 'Cash';
                    case 'card': return 'Card';
                    case 'cheque': return 'Cheque';
                    case 'bacs': return 'BACS';
                    case 'stripe': return 'Stripe';
                }
                return value;
        }
        return value;
    }

    static formatDuration(mins, interval) {
        mins = Math.ceil(mins || 0);
        if (typeof (interval) == 'undefined') {
            interval = 15;
        }
        const parts = [];
        mins = Math.floor(mins * interval) / interval;
        const hours = Math.floor(mins / 60);
        if (hours > 0) {
            parts.push(hours + 'hr');
            mins -= hours * 60;
        }
        if (mins > 0) {
            parts.push(mins + 'min');
        }
        return parts.join(' ');
    }

    static buildURL(url, queryString) {
        let isFirstQS = true;
        if (queryString) {
            for (var key in queryString) {
                let value = queryString[key];
                if (value) {
                    url += (isFirstQS ? '?' : '&') + key + '=' + encodeURIComponent(value);
                    isFirstQS = false;
                }
            }
        }
        return url;
    }

    static formatAddress(address, separator) {
        if (address == null) return '';
        if (typeof (separator) == 'undefined') {
            separator = ', ';
        }
        const result = [
            address.line1,
            address.line2,
            address.line3,
            address.town,
            address.postcode
        ].map(a => (a || '').trim())
            .filter(a => a)
            .join(separator);
        return result;
    }

    static escapeHTML(html) {
        html = html.replace(/&/g, '&amp;');
        html = html.replace(/>/g, '&gt;');
        html = html.replace(/</g, '&lt;');
        return html;
    }

    static escapeJSString(str) {
        str = str || '';
        str = str.replace(/'/g, '\\\'');
        str = str.replace(/\r/g, '\\r');
        str = str.replace(/\n/g, '\\n');
        return str;
    }

    static escapeAttribute(str) {
        str = str || '';
        str = str.replace(/"/g, '&quot;');
        str = str.replace(/\\/g, '\\\\');
        return str;
    }

    static getApptSummary(appt) {
        const chimneys = {};
        const services = {};
        appt.services.forEach(ast => {
            const serviceTypeCode = (ast.serviceType.code ? ast.serviceType.code.toUpperCase() : ast.serviceType.name);
            switch (serviceTypeCode) {
                case 'SWEEP':
                    const chimneyTypeCode = (ast.chimneyType && ast.chimneyType.code ? ast.chimneyType.code : 'OTHER');
                    chimneys[chimneyTypeCode] = (chimneys[chimneyTypeCode] || 0) + 1;
                    break;
                default:
                    services[serviceTypeCode] = (services[serviceTypeCode] || 0) + 1;
                    break;
            }
        });
        const results = [];
        Object.keys(chimneys).forEach(c => {
            results.push(`${chimneys[c]} × ${c}`);
        });
        Object.keys(services).forEach(s => {
            results.push(`${services[s]} × ${s}`);
        });
        return results.join(', ');
    }

    static normalisePostcode(postcode) {
        // Basic normalise
        postcode = postcode || '';
        postcode = postcode.toUpperCase();
        postcode = postcode.replace(/[^A-Z0-9]/, '');

        // Add a space at the correct place
        let insertPos = -1;
        switch (postcode.length) {
            case 5: insertPos = 2; break;
            case 6: insertPos = 3; break;
            case 7: insertPos = 4; break;
        }
        if (insertPos != -1) {
            postcode = postcode.slice(0, insertPos) + ' ' + postcode.slice(insertPos);
        }
        return postcode;
    }

    static isValidUKPostcode(postcode) {
        // https://www.qodo.co.uk/blog/javascript-check-if-a-uk-postcode-is-valid/
        // Yes I know this doesn't cover 100% of valid UK postcodes
        const postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i;
        return postcodeRegEx.test(postcode); 
    }

    static formatNumber(amount) {
        let result = Number(amount || 0).toLocaleString();
        var parts = result.split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        result = parts.join('.');
        return result;
    }
}

window.TextHelpers = TextHelpers;
