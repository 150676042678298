import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import Print from 'Print';
import FormHelper from 'helpers/FormHelper';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class AddRebookModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedYear: new Date().getFullYear()
        };

        this.form = new FormHelper({
            fields: {
                rebookMonths: {
                    label: 'Rebook Month(s)',
                    type: 'checkboxes',
                    wrapperClassName: 'rebook-months',
                    getOptions: () => ReferenceHelpers.Months
                }
            },
            getValue: (fieldName) => this.state.property[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.isChanged = false;
            this.onSave = opt.onSave;
            this.setState({
                isOpen: true,
                isLoading: true,
                customerID: opt.customerID,
                propertyID: opt.propertyID,
                property: null
            }, () => {
                this.load();
            });
        });
    }

    async load() {
        const { propertyID } = this.state;
        this.setState({ isLoading: true });
        const property = await API.call(`property/get/${propertyID}`);
        this.setState({
            property,
            isLoading: false
        });
    }

    close() {
        this.setState({
            isOpen: false
        });
    }

    async saveAndClose() {
        const { customerID, propertyID, property, selectedYear } = this.state;
        this.setState({ isLoading: true });
        await API.call('rebook/save-for-property', {
            propertyID,
            customerID,
            rebookMonths: property.rebookMonths,
            year: selectedYear
        });
        this.setState({ isLoading: false });
        this.close();
        if (this.onSave) {
            this.onSave();
        }
    }

    updateFields(fields) {
        const property = { ...this.state.property };
        let anyChanged = false;

        for (let fieldName in fields) {
            const oldValue = property[fieldName];
            const value = fields[fieldName];
            if (value != oldValue) {
                anyChanged = true;
                property[fieldName] = value;
            }
        }

        if (anyChanged) {
            this.setState({ property });
            this.isChanged = true;
        }
    }

    render() {
        const {
            isOpen,
            isLoading,
            property,
            selectedYear
        } = this.state;

        if (!isOpen) {
            return null;
        }
        const thisYear = new Date().getFullYear();

        return (
            <Modal show onHide={() => this.close()} className="edit-property-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault(); e.stopPropagation(); }}>
                        <Modal.Header>
                            <Modal.Title>Add  new rebook</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className="form-group">
                                <label className="form-label">Property</label>
                                <p className="form-control-plaintext">
                                    {property.name}
                                </p>
                            </div>

                            {this.form.render([ 'rebookMonths' ])}

                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <div style={{ marginRight: '10px' }}>Year</div>
                                
                                <select
                                    className="form-select"
                                    style={{ width: 100 }}
                                    defaultValue={selectedYear}
                                    onChange={(e) => this.setState({ selectedYear: e.currentTarget.value })}
                                >
                                    <option key={thisYear} value={thisYear}>{thisYear}</option>
                                    <option key={thisYear + 1} value={thisYear + 1}>{thisYear + 1}</option>
                                    <option key={thisYear + 2} value={thisYear + 2}>{thisYear + 2}</option>
                                    <option key={thisYear + 3} value={thisYear + 3}>{thisYear + 3}</option>
                                </select>
                                
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary me-auto" onClick={() => this.close()}>
                                Cancel
                            </button>
                            <button type="submit" className="btn btn-primary">
                                Save & Close
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

}

export default AddRebookModal;

//import {useState, forwardRef, useImperativeHandle} from 'react';
//import Modal from 'react-bootstrap/Modal';
//import FormHelper from 'helpers/FormHelper';
//import ReferenceHelpers from 'helpers/ReferenceHelpers';
//import {Button} from 'react-bootstrap';

//const AddRebookModal = forwardRef(({ onSave }, ref) => {
//    const [properties, setProperties] = useState(null);
//    const [selectedProperty, setSelectedProperty] = useState(null);
//    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
//    const [isOpen, setIsOpen] = useState(false);


//    useImperativeHandle(ref, () => {
//        return {
//            open(properties) {
//                setProperties(properties);
//                setSelectedProperty(properties[0]);
//                setIsOpen(true);
//            }
//        }
//    })

//    const save = () => {
//        setIsOpen(false);
//        onSave(selectedProperty, selectedYear);
//    }

//    const close = () => {
//        setIsOpen(false);
//    }

//    if (isOpen && properties) {
//        return (<>
//            <Modal show onHide={() => this.close()} centered backdrop="static" keyboard={false}>
//                <Modal.Header>
//                    <Modal.Title>Add new rebook</Modal.Title>
//                </Modal.Header>
//                <Modal.Body>
//                    <div>Select property:</div>
//                    <select className="form-select" defaultValue={properties[0].id} name="Test" style={{ marginBottom: '10px' }} onChange={(e) => setSelectedProperty(properties.find(p => p.id == e.currentTarget.value)) }>
//                        {properties.map((property) => (
//                            <option key={property.id} value={property.id}>{property.name}</option>
//                        ))}
//                    </select>

//                    {!!selectedProperty && form.render([
//                        'rebookMonths'
//                    ])}    

//                    <div style={{ display: 'flex', alignItems: 'center'}}>
//                        <div style={{ marginRight: '10px' }}>Year</div>

//                        <select className="form-select" style={{ width: '100px'}} defaultValue={selectedYear} onChange={(e) => setSelectedYear(e.currentTarget.value)}>
//                            <option key={new Date().getFullYear()} value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
//                            <option key={new Date().getFullYear()+1} value={new Date().getFullYear()+1}>{new Date().getFullYear()+1}</option>
//                            <option key={new Date().getFullYear()+2} value={new Date().getFullYear()+2}>{new Date().getFullYear()+2}</option>
//                            <option key={new Date().getFullYear()+3} value={new Date().getFullYear()+3}>{new Date().getFullYear()+3}</option>
//                        </select>
//                    </div>    
//                </Modal.Body>

//                <Modal.Footer>
//                    <Button variant="secondary" onClick={() => close()}>Cancel</Button>
//                    <Button variant="primary" onClick={() => save()}>Save</Button>
//                </Modal.Footer>
//            </Modal>
//        </>)
//    }

//    return null;
//})

//export default AddRebookModal;
