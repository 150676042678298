import React from 'react';
import { Calendar, momentLocalizer } from 'lib/react-big-calendar';
import withDragAndDrop from 'lib/react-big-calendar/addons/dragAndDrop';
import moment from 'moment';
import 'moment/locale/en-gb';
import queryString from 'query-string';
import ExpandingTextArea from 'react-expanding-textarea';

// Services & Helpers
import API from 'API';
import Print from 'Print';
import DateHelpers from 'helpers/DateHelpers';
import TextHelpers from 'helpers/TextHelpers';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Map from 'components/appt/Map';
import EditAppt from 'components/appt/EditAppt';
import DatePicker from 'components/common/DatePicker';
import Loader from 'components/common/Loader';
import ErrorBoundary from 'components/ErrorBoundary';
import CustomerCommunicationModal from 'components/customer/CustomerCommunication/CustomerCommunicationModal';
import PreviewEmailModal from 'components/outgoing/PreviewEmailModal';

// CSS
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const calendarLocaliser = momentLocalizer(moment)
const DragAndDropCalendar = withDragAndDrop(Calendar);

//-----------------------------------------------------------------

class DaySheet extends React.Component {

    constructor(props) {
        super(props);

        this.editCustomerCommModalRef = React.createRef();
        this.previewEmailModalRef = React.createRef();
        this.moveOrResize = this.moveOrResize.bind(this);
        
        this.state = {
            isLoading: true,
            time: null,
            sweeps: null,
            allSweeps: null,
            sweepUserID: null,
            duration: 45,
            isPrintingDaySheet: {},
            hideMapLines: props.loginInfo.user.hideMapLines,
            rememberDate: (localStorage.getItem('day-sheet-remember-date') == 'true'),
            hideMap: (localStorage.getItem('day-sheet-hide-map') == 'true'),
            verticalMap: (localStorage.getItem('day-sheet-vertical-map') == 'true')
        };

        // Load initial view from queryString
        const qs = queryString.parse(this.props.history.location.search);
        if (qs.date) {
            this.state.startDate = new Date(qs.date);
        }
        // If date not specified and we are set to remember date
        else if (this.state.rememberDate) {
            try {
                const daySheetDateRememberedOn = localStorage.getItem('day-sheet-date-remembered-on');
                if (!daySheetDateRememberedOn || daySheetDateRememberedOn == moment().format('YYYY-MM-DD')) {
                    let date = localStorage.getItem('day-sheet-date');
                    if (date) {
                        date = new Date(date);
                        this.state.startDate = date;
                    }
                    this.state.view = localStorage.getItem('day-sheet-view');
                }
            } catch (error) {
            }
        }
        if (!this.state.startDate) {
            this.state.startDate = moment().startOf('day').toDate();
        }
        if (!this.state.view) {
            this.state.view = 'day';
        }
        this.state.date = this.state.startDate;

        if (qs.apptID) {
            this.state.isEditingAppt = true;
            this.state.apptID = parseInt(qs.apptID);
        }
        if (qs.customerID) {
            this.state.isEditingAppt = true;
            this.state.customerID = parseInt(qs.customerID);
        }
        if (qs.propertyID) {
            this.state.isEditingAppt = true;
            this.state.propertyID = parseInt(qs.propertyID);
        }
        if (qs.rebookID) {
            this.state.rebookID = parseInt(qs.rebookID);
        }
        if (qs.prevPage) {
            this.state.prevPage = qs.prevPage;
        }
    }

    componentDidMount() {
        this.load();
        this.props.fns.setTitle('Day Sheet');
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.viewSweepUserID !== prevState.viewSweepUserID) {
            this.load();
        }
    }


    async debounceLoad() {
        window.clearTimeout(this.loadTimeout);
        this.loadTimeout = window.setTimeout(() => {
            this.load();
        }, 200);
    }

    async load() {
        const { startDate, view } = this.state;
        let sweepUserID = this.state.viewSweepUserID;
        this.setState({ areApptsLoading: true });
        // Load sweeps
        let { allSweeps, viewSweepUserID } = this.state;
        if (!allSweeps) {
            allSweeps = await API.call('user/list', { isSweep: true });
            if (!viewSweepUserID && allSweeps.length == 1) {
                viewSweepUserID = allSweeps[0].id;
            }
        }
        const sweeps = (sweepUserID ? allSweeps.filter(s => s.id == sweepUserID) : [ ...allSweeps ]);
        const sweepsByID = {};
        sweeps.forEach(s => {
            sweepsByID[s.id] = s;
        });

        // Load appointments
        const date = moment(startDate).format('YYYY-MM-DD');
        const daySheet = await API.call('appt/get-day-sheet', {
            date,
            sweepUserID,
            view
        });
        if (moment(daySheet.date).format('YYYY-MM-DD') != date) {
            return;
        }

        // Group appts by sweep
        const apptsBySweep = {};
        daySheet.appts.forEach(appt => {
            if (appt.property && appt.property.address && appt.property.address.latitude && appt.property.address.longitude) {
                if (!apptsBySweep[appt.sweepUserID]) {
                    apptsBySweep[appt.sweepUserID] = [];
                }
                apptsBySweep[appt.sweepUserID].push(appt);
            }
        });

        this.setState({
            isLoading: false,
            areApptsLoading: false,
            appts: daySheet.appts,
            viewSweepUserID,
            daySheetNotes: daySheet.notes,
            apptsBySweep,
            allSweeps,
            sweeps,
            rotas: daySheet.rotas,
            sweepsByID,
            travelStatsByAppt: {},
            travelStatsBySweep: {}
        }, () => {
            this.debounceLoadTravelStats();
        });
    }

    debounceLoadTravelStats() {
        clearTimeout(this.loadTravelStatsTimeout);
        this.loadTravelStatsTimeout = setTimeout(() => {
            this.loadTravelStats();
        }, 500);
    }

    async loadTravelStats() {
        const { appt, sweeps, sweepsByID, isRescheduling, startDate, view } = this.state;
        const { showWarningOverXMins } = this.props.loginInfo.account;
        const appts = [...this.state.appts];
        let apptsBySweep = { ...this.state.apptsBySweep };
        let checkTravelTime = false;
        let warnTravelTime = false;

        // Don't bother getting this for past appts
        let endDateSerial = parseInt(moment(startDate).format('YYMMDD'));
        if (view == 'week') endDateSerial += 7;
        else endDateSerial += 1;
        if (endDateSerial < parseInt(moment().format('YYMMDD'))) {
            return;
        }

        this.setState({ warnTravelTime: false });

        // Init travel stats
        let travelStats = {};
        const travelStatsByAppt = {};
        const travelStatsBySweep = {};
        sweeps.forEach(sweep => {
            travelStatsBySweep[sweep.id] = {
                travelTime: 0,
                distance: 0
            }
        });

        if (appts) {
            // If editing a new appt or rescheduling existing, insert it into the list
            if (appt && (!appt.id || isRescheduling) && appt.property && appt.time && appt.sweepUserID) {
                appts.push(appt);
                apptsBySweep[appt.sweepUserID] = [...apptsBySweep[appt.sweepUserID] || [], appt];
                DateHelpers.sortApptList(apptsBySweep[appt.sweepUserID]);
                checkTravelTime = !!showWarningOverXMins;
            }

            // Init travel stats by appt
            appts.forEach(appt => {
                travelStatsByAppt[appt.id] = {};
            });

            // Get a list of postcode pairs
            const postcodePairs = [];
            Object.keys(apptsBySweep).forEach(sweepUserID => {
                const appts = apptsBySweep[sweepUserID];
                const sweep = sweepsByID[sweepUserID];
                if (!sweep || !appts) return;

                // Base -> First Appt
                if (sweep.baseAddress && appts.length > 0) {
                    postcodePairs.push(`${sweep.baseAddress.postcode}-${appts[0].property.address.postcode}`);
                }

                // Appt -> Next Appt
                appts.forEach((appt, index) => {
                    if (index < appts.length - 1) {
                        const nextAppt = appts[index + 1];
                        if (appt.property && nextAppt.property) {
                            postcodePairs.push(`${appt.property.address.postcode}-${nextAppt.property.address.postcode}`);
                        }
                    }
                });

                // Last Appt -> Base
                if (sweep.baseAddress && appts.length > 0) {
                    postcodePairs.push(`${appts[appts.length - 1].property.address.postcode}-${sweep.baseAddress.postcode}`);
                }
            });

            // Get stats
            travelStats = await API.call('appt/get-travel-stats', {
                postcodePairs
            });

            // Put travel stats into the right places
            Object.keys(apptsBySweep).forEach(sweepUserID => {
                const appts = apptsBySweep[sweepUserID];
                const sweep = sweepsByID[sweepUserID];
                const sweepTravelStat = travelStatsBySweep[sweepUserID];
                if (!sweep || !appts) return null;

                // Home -> First Appt
                if (sweep.baseAddress && appts.length > 0) {
                    const key = `${sweep.baseAddress.postcode}-${appts[0].property.address.postcode}`;
                    const travelStat = travelStats[key];
                    if (travelStat) {
                        if (!appts[0].id && travelStat.travelTime >= showWarningOverXMins) {
                            warnTravelTime = true;
                        }
                        sweepTravelStat.travelTime += travelStat.travelTime;
                        sweepTravelStat.distance += travelStat.distance;
                    }
                }

                // Appt -> Next Appt
                appts.forEach((appt, index) => {
                    if (index < appts.length - 1) {
                        const nextAppt = appts[index + 1];
                        if (appt.property && nextAppt.property) {
                            const key = `${appt.property.address.postcode}-${nextAppt.property.address.postcode}`;
                            const travelStat = travelStats[key];
                            if (checkTravelTime) {
                                if ((!appt.id || !nextAppt.id) && travelStat.travelTime >= showWarningOverXMins) {
                                    warnTravelTime = true;
                                }
                            }
                            travelStatsByAppt[appt.id] = travelStat;
                            sweepTravelStat.travelTime += travelStat.travelTime;
                            sweepTravelStat.distance += travelStat.distance;
                        }
                    }
                });

                // Last Appt -> Home
                if (sweep.baseAddress && appts.length > 0) {
                    const key = `${appts[appts.length - 1].property.address.postcode}-${sweep.baseAddress.postcode}`;
                    const travelStat = travelStats[key];
                    if (travelStat) {
                        sweepTravelStat.travelTime += travelStat.travelTime;
                        sweepTravelStat.distance += travelStat.distance;
                    }
                }
            });

            // Show travel time warning
            if (warnTravelTime) {
               this.setState({ warnTravelTime: true });
            }
        }

        this.setState({
            travelStats,
            travelStatsByAppt,
            travelStatsBySweep
        });
    }

    setDate(date, view) {
        if (!view) view = this.state.view;
        date = moment(date).startOf('day').toDate();

        if (!this.state.startDate || moment(date).format('YYYY-MM-DD') != moment(this.state.startDate).format('YYYY-MM-DD') || this.state.view != view) {
            const startDate = (view == 'day' ? date : moment(date).startOf('isoWeek').toDate());

            this.setState({
                startDate,
                date,
                view,
                appts: []
            }, () => {
                this.debounceLoad();
                this.saveDate();
            });
        }
    }

    ensureDateInView(date) {
        const { startDate, view } = this.state;
        const earliestDate = moment(startDate);
        const latestDate = view === 'day' ? earliestDate : earliestDate.clone().add(6, 'days');

        const dateMoment = moment(date);
        if (dateMoment.isBefore(earliestDate) || dateMoment.isAfter(latestDate)) {
            this.setDate(date);
        }
    }

    setSweep(viewSweepUserID) {
        if (viewSweepUserID != this.state.viewSweepUserID) {
            this.setState({
                viewSweepUserID
            }, () => {
                this.load();
            });
        }
    }

    moveDate(delta) {
        const { view } = this.state;
        const numDays = (view == 'week' ? 7 : 1);
        const date = moment(this.state.startDate).add(delta * numDays, 'days').toDate();
        this.setDate(date, view);
    }

    selectSlot(start, sweepUserID) {
        const newState = {};
        if (!this.state.isRescheduling) {
            newState.isEditingAppt = true;
            newState.apptID = null;
            this.debounceLoadTravelStats();
        }
        if (start) {
            newState.date = moment(start).startOf('day').toDate();
            newState.time = moment(start).format('HH:mm');
        } else {
            newState.date = moment(this.state.startDate).startOf('day').toDate();
            newState.time = null;
        }
        if (sweepUserID) {
            newState.sweepUserID = sweepUserID;
        }
        this.setState(newState);
    }

    cancelNewAppt() {
        this.props.history.replace('/day-sheet');
        this.setState({
            customerID: null,
            propertyID: null,
            customer: null,
            property: null,
            isRescheduling: false
        });
    }

    toggleAppt(apptID) {
        if (this.state.isRescheduling) return;
        if (this.state.isEditingAppt && apptID == this.state.apptID) {
            this.cancelEditingAppt(false);
        } else {
            this.loadAppt(apptID);
        }
    }

    loadAppt(apptID) {
        this.setState({
            isEditingAppt: true,
            apptID
        });
    }

    cancelEditingAppt(reload) {
        // If we were editing a new appt that had a time and property, 
        // the travel stats will now be out of date, so update them
        const { appt, isRescheduling } = this.state;
        if ((!appt.id && appt.property && appt.sweep) || isRescheduling) {
            this.debounceLoadTravelStats();
        }

        // Remove any extra information from the URL
        this.props.history.replace('/day-sheet');

        // Update
        this.setState({
            isEditingAppt: false,
            appt: null,
            customerID: null,
            isRescheduling: false,
            duration: 45,
            warnTravelTime: false
        }, () => {
            if (reload) {
                this.load();
            }
        });
    }

    moveOrResize(info) {
        const time = moment(info.start).format('HH:mm');
        let duration = moment(info.end).diff(info.start, 'minutes');
        if (duration < 45) {
            duration = 45;
        }
        const sweepUserID = parseInt(info.resourceId);
        if (info.event.apptID) {
            const confirm = window.confirm('Are you sure you want to move/resize this appointment?');
            if (confirm) {
                // Update on screen
                const appts = [...this.state.appts];
                const index = appts.findIndex(a => a.id == info.event.apptID);
                const appt = { ...appts[index] };
                appt.sweepUserID = sweepUserID;
                appt.time = time;
                appt.duration = duration;
                appts[index] = appt;
                this.setState({
                    appts
                }, async () => {
                    // Update on server
                    const { sendBookingNotification } = await API.call('appt/move-from-calendar/' + info.event.apptID, {
                        time,
                        duration,
                        sweepUserID
                    });
                    this.load();

                    // Offer to resend booking notification
                    if (sendBookingNotification) {
                        this.confirmResendBookingNotification(info.event.apptID);
                    }
                });
            }
        } else {
            this.setState({
                time,
                sweepUserID,
                duration
            });
        }
    }

    async confirmResendBookingNotification(apptID) {
        const send = await this.previewEmailModalRef.current.open({
            canSend: true,
            email: {
                type: 'ApptBooked',
                apptID
            }
        });
        if (send) {
            await API.call('appt/trigger-notification', {
                apptID,
                type: 'ApptBooked'
            });
        }
    }

    updateAppt(appt) {
        const oldAppt = this.state.appt;
        this.setState({
            appt
        }, () => {
            if (appt && appt.property && (!oldAppt || !oldAppt.property || oldAppt.propertyID != appt.propertyID || appt.time != oldAppt.time || appt.sweepUserID != oldAppt.sweepUserID)) {
                this.debounceLoadTravelStats();
            }
        });
    }

    async printDaySheet(sweepUserID) {
        sweepUserID = parseInt(sweepUserID);

        // Update UI
        let isPrintingDaySheet = { ...this.state.isPrintingDaySheet }; 
        if (isPrintingDaySheet[sweepUserID]) {
            return;
        }
        isPrintingDaySheet[sweepUserID] = true;
        this.setState({ isPrintingDaySheet });

        // Print
        await Print.url('/api/appt/get-day-sheet-pdf', {
            date: moment(this.state.startDate).format('YYYY-MM-DD'),
            sweepUserID,
            isInline: true
        }); 

        // Update UI
        isPrintingDaySheet = { ...this.state.isPrintingDaySheet };
        delete isPrintingDaySheet[sweepUserID];
        this.setState({ isPrintingDaySheet });
    }

    updateDaySheetNotes(daySheetNotes) {
        const { startDate, viewSweepUserID } = this.state;
        if (this.state.daySheetNotes != daySheetNotes) {
            this.setState({ daySheetNotes });
            API.call('appt/update-day-sheet-notes', {
                date: moment(this.state.startDate).format('YYYY-MM-DD'),
                sweepUserID: viewSweepUserID,
                notes: daySheetNotes
            });
        }
    }

    onSave(appt, rebooks) {
        const { prevPage, isRescheduling } = this.state;
        let addCustomerComm = !!appt.customerID;
        switch (prevPage) {
            case 'rebooks':
                this.props.history.push(`/rebooks`);
                addCustomerComm = false;
                break;
        }
        if (isRescheduling) {
            this.setState({ isRescheduling: false });
            addCustomerComm = false;
        }
        
        if (addCustomerComm) {
            this.editCustomerCommModalRef.current.open({
                customerID: appt.customerID,
                propertyID: appt.propertyID,
                type: 'Note',
                setNoneAsDefaultRebook: true
            });
        }
        this.debounceLoad();
    }

    toggleView() {
        let { view, date } = this.state;
        view = (view == 'day' ? 'week' : 'day');
        if (view == 'week') {
            date = moment(date).startOf('isoWeek').toDate();
        }
        this.setDate(date, view);
    }

    reschedule() {
        const { appt } = this.state;
        this.setState({
            isRescheduling: true,
            date: appt.date,
            time: appt.time,
            sweepUserID: appt.sweepUserID
        });
    }

    setHideMapLines(hideMapLines) {
        this.setState({ hideMapLines });
        API.call('account/set-hide-map-lines/' + hideMapLines);
    }

    setRememberDate(rememberDate) {
        localStorage.setItem('day-sheet-remember-date', rememberDate);
        if (rememberDate) {
            this.saveDate();
        } else {
            localStorage.removeItem('day-sheet-date');
            localStorage.removeItem('day-sheet-view');
            localStorage.removeItem('day-sheet-date-remembered-on');
        }
        this.setState({ rememberDate });
    }

    setHideMap(hideMap) {
        if (hideMap) {
            localStorage.setItem('day-sheet-hide-map', 'true');
        } else {
            localStorage.removeItem('day-sheet-hide-map');
        };
        this.setState({ hideMap });
    }

    setVerticalMap(verticalMap) {
        if (verticalMap) {
            localStorage.setItem('day-sheet-vertical-map', 'true');
        } else {
            localStorage.removeItem('day-sheet-vertical-map');
        };
        this.setState({ verticalMap });
    }
    
    saveDate() {
        localStorage.setItem('day-sheet-date-remembered-on', moment().format('YYYY-MM-DD'));
        localStorage.setItem('day-sheet-date', moment(this.state.date).format('YYYY-MM-DD'));
        localStorage.setItem('day-sheet-view', this.state.view);
    }

    //------------------------------------------------------------------------------------------------------------

    render() {
        const {
            isLoading,
            startDate,
            viewSweepUserID,
            isEditingAppt,
            daySheetNotes,
            allSweeps,
            view,
            isRescheduling,
            hideMap,
            verticalMap
        } = this.state;

        if (isLoading) {
            return (
                <section>
                    <Loader />
                </section>
            );
        }

        return (<>

            <section className="day-sheet control-panel">

                <div className="control-panel-component">

                    <button type="button" className="btn btn-primary" onClick={() => this.moveDate(-1)}>
                        <span className="fa-solid fa-chevron-left" />
                    </button>
                    <DatePicker
                        className="form-control"
                        wrapperClassName="date-picker"
                        value={startDate}
                        onChange={date => this.setDate(date, 'day')}
                    />
                    <button type="button" className="btn btn-primary" onClick={() => this.moveDate(+1)}>
                        <span className="fa-solid fa-chevron-right" />
                    </button>

                </div>

                <div className="control-panel-component">

                    <button type="button" className="btn btn-tertiary" onClick={() => this.setDate(new Date(), 'day')}>
                        <span className="fa-solid fa-calendar-day" />{' '} 
                        Today
                    </button>

                </div>

                <div className="control-panel-component">

                    <button type="button" className="btn btn-tertiary" onClick={() => this.toggleView()}>
                        {view == 'day' ? 'Week view' : 'Day view'}
                    </button>

                </div>
                
                <select
                    className="control-panel-component form-control sweep-selector"
                    value={viewSweepUserID || ''}
                    onChange={e => this.setSweep(e.target.value)}
                >
                    <option value="">(All sweeps)</option>
                    {allSweeps.map(sweep =>
                        <option key={sweep.id} value={sweep.id}>
                            {sweep.nickname}
                        </option>
                    )}
                </select>

                <div className="control-panel-component current-date">
                    {moment(startDate).format('dddd, DD MMMM YY')}
                </div>

                {!!viewSweepUserID && view == 'day' &&
                    <div className="control-panel-component day-sheet-notes">

                        <ExpandingTextArea
                            className="form-control"
                            rows={1}
                            key={daySheetNotes || ''}
                            defaultValue={daySheetNotes || ''}
                            onBlur={e => this.updateDaySheetNotes(e.target.value)}
                            placeholder="Notes for the day..."
                        />

                    </div>
                }

                <div className="control-panel-component ms-auto">

                    {this.renderOptionsDropdown()}

                    {!isEditingAppt &&
                        <button type="button" className="btn btn-primary" onClick={() => this.selectSlot(null, null)}>
                            <span className="fa-regular fa-calendar-plus" />{' '}
                            Add Appt
                        </button>
                    }
                </div>

                {isRescheduling &&
                    <div className="control-panel-component ms-auto alert alert-info">
                        Rescheduling appointment
                        <button className="btn btn-sm btn-secondary ms-3" onClick={() => this.setState({ isRescheduling: false })}>
                            Cancel
                        </button>
                    </div>
                }

            </section>

            <div className={`day-sheet-page ${hideMap ? 'hide-map' : ''} ${verticalMap ? 'vertical-map' : ''}`}>

                <section className="left">

                    {this.renderCalendar()}

                    {!hideMap && this.renderMap()}

                </section>

                {isEditingAppt &&
                    <section className="right">

                        {this.renderCurrentAppt()}

                    </section>
                }

            </div>

            <CustomerCommunicationModal ref={this.editCustomerCommModalRef} />
            <PreviewEmailModal ref={this.previewEmailModalRef} />

        </>);
    }

    renderCalendar() {
        const { loginInfo } = this.props;
        const { showTravelTimeAfterXMins } = loginInfo.account;
        const { allSweeps, sweeps, appt, date, startDate, time, duration, sweepUserID, isEditingAppt, travelStatsByAppt, sweepsByID, view, isRescheduling } = this.state;

        // Map appts to events
        const appts = [...this.state.appts];
        if (isEditingAppt) {
            if (appt) {
                if (!appt.id) {
                    //appts.push({ ...appt, sweepUserID, date, time });
                    appts.push(appt);
                }

                // If rescheduling, show a ghost for the suggested new time
                if (isRescheduling && date && time && sweepUserID) {
                    appts.push({
                        date, time, sweepUserID,
                        duration: appt.duration
                    });
                }
            }
            else if (date && time && duration && sweepUserID) {
                // Show ghost for new appt
                appts.push({
                    date, time, duration, sweepUserID
                });
            }
            DateHelpers.sortApptList(appts);
        }
        
        const events = [];
        const backgroundEvents = [];

        appts.forEach(a => {
            let start = DateHelpers.combineDateAndTime(a.date, a.time);
            let end = moment(start).add(a.duration, 'minutes').toDate();
            let travelTime = 0;
            if (travelStatsByAppt) {
                const travelStat = travelStatsByAppt[a.id];
                if (travelStat) {
                    travelTime = travelStat.travelTime;
                }
            }

            //// If the event spans multiple days, split it into multiple events
            //// (RBC doesn't seem to render it at all otherwise)
            //const startDateSerial = parseInt(moment(a.date).format('YYMMDD'));
            //const endDateSerial = parseInt(moment(a.date).add(a.duration, 'minutes').format('YYMMDD'));
            //if (endDateSerial != startDateSerial) {
            //    const diff = endDateSerial - startDateSerial;
            //    end = new Date(moment(start).format('YYYY-MM-DD') + 'T23:45');

            //    for (let i = 1; i <= diff; i++) {
            //        events.push({
            //            ...a,
            //            id: a.id + '-' + i,
            //            type: 'appt',
            //            nonBookingApptType: a.nonBookingApptType,
            //            recurringApptID: a.recurringApptID,
            //            apptID: a.id,
            //            resourceId: `${a.sweepUserID}`,
            //            className: (a.id ?
            //                (appt && a.id == appt.id ? 'active-appt' : '') :
            //                'new-appt'
            //            ),
            //            start: moment(a.date).add(i, 'days').startOf('day').toDate(),
            //            end: moment(moment(a.date).add(i, 'days').format('YYYY-MM-DD')).toDate(),
            //            travelTime
            //        });
            //    }
            //}

            // React-big-calendar doesn't show the event at all if the start or end fall outside of today
            if (startDate && parseInt(moment(start).format('YYMMDDHHmm')) < parseInt(moment(startDate).format('YYMMDDHHMM'))) {
                start = new Date(moment(startDate).format('YYYY-MM-DD') + 'T08:00');
            }
            if (moment(end).format('YYYY-MM-DD') != moment(start).format('YYYY-MM-DD')) {
                end = new Date(moment(start).format('YYYY-MM-DD') + 'T23:45');
            }

            // Add appt event
            events.push({
                ...a,
                type: 'appt',
                nonBookingApptType: a.nonBookingApptType,
                recurringApptID: a.recurringApptID,
                apptID: a.id,
                resourceId: `${a.sweepUserID}`,
                className: (a.id ?
                    (appt && a.id == appt.id ? 'active-appt' : '') :
                    'new-appt'
                ),
                start,
                end,
                travelTime
            });

            // Add travel time event
            if (typeof (showTravelTimeAfterXMins) && showTravelTimeAfterXMins !== null && travelTime >= showTravelTimeAfterXMins) { // TODO make this configurable
                // Get time (end of the appt, plus travel time)
                start = moment(end).toDate();
                end = moment(start).add(Math.max(travelTime, 45), 'minutes').toDate();

                // Map to event
                backgroundEvents.push({
                    type: 'travel-time',
                    id: `travel-time-${a.id}`,
                    apptID: a.id,
                    resourceId: `${a.sweepUserID}`,
                    isBackgroundEvent: true,
                    className: 'travel-time-appt',
                    start,
                    end,
                    travelTime
                });
            }
        });

        // Get rota info
        const dates = [startDate];
        if (view == 'week') {
            const dateMoment = moment(startDate);
            for (let i = 0; i < 6; i++) {
                dateMoment.add(1, 'day');
                dates.push(dateMoment.toDate());
            }
        }
        for (let i = 0; i < dates.length; i++) {
            const date = dates[i];
            const dowNumber = moment(date).isoWeekday();
            const dow = TextHelpers.DaysOfWeek[dowNumber - 1];
            const rotaDay = this.state.rotas[dow];

            if (rotaDay) {
                for (let i = 0; i < sweeps.length; i++) {
                    const sweep = sweeps[i];
                    const rotaDaySweep = rotaDay.find(rd => rd.userID == sweep.id);
                    const endOfDay = moment(date).add(1, 'day').add(-15, 'minutes').toDate();

                    // Not working at all
                    if (!rotaDaySweep) {
                        backgroundEvents.push({
                            type: 'non-working-time',
                            className: 'non-working-time',
                            resourceId: `${sweep.id}`,
                            isBackgroundEvent: true,
                            start: moment(date).toDate(),
                            end: endOfDay
                        });
                        continue;
                    }

                    // Start time
                    if (rotaDaySweep.startTime && rotaDaySweep.startTime != '08:00') {
                        backgroundEvents.push({
                            type: 'non-working-time',
                            className: 'non-working-time',
                            resourceId: `${sweep.id}`,
                            isBackgroundEvent: true,
                            start: date,
                            end: moment(moment(date).format('YYYY-MM-DD') + ' ' + rotaDaySweep.startTime).toDate()
                        });
                    }

                    // Finish time
                    if (rotaDaySweep.finishTime) {
                        backgroundEvents.push({
                            type: 'non-working-time',
                            className: 'non-working-time',
                            resourceId: `${sweep.id}`,
                            isBackgroundEvent: true,
                            start: moment(moment(date).format('YYYY-MM-DD') + ' ' + rotaDaySweep.finishTime).toDate(),
                            end: endOfDay
                        });
                    }
                }
            }
        }

        // Map sweeps to resources
        const resources = sweeps.map(s => ({
            resourceId: `${s.id}`,
            resourceTitle: s.nickname
        }));
        
        // TODO fix the scrollToTime not working bug
        //min={new Date(1900, 1, 1, 0, 30, 0)}
        //scrollToTime={new Date(1900, 1, 1, 8, 0, 0)}
        return (

            <DragAndDropCalendar
                localizer={calendarLocaliser}
                events={events}
                backgroundEvents={backgroundEvents}
                min={new Date(1900, 1, 1, 8, 0, 0)}
                max={new Date(1900, 1, 1, 23, 45, 0)}
                views={['day', 'week']}
                view={view}
                date={new Date(startDate)}
                step={45}
                timeslots={1}
                resourceIdAccessor="resourceId"
                resourceTitleAccessor="resourceTitle"
                resources={resources}
                onNavigate={e => { /* NOP */ }}
                onView={e => { /* NOP */ }}
                onEventDrop={this.moveOrResize}
                onEventResize={this.moveOrResize}
                draggableAccessor={(event) => event.status == 'Open'}
                resizeableAccessor={() => false}
                onSelecting={() => false}
                selectable
                onSelectEvent={event => this.toggleAppt(event.apptID)}
                onSelectSlot={(slot) => this.selectSlot(slot.start, slot.resourceId)}
                dayLayoutAlgorithm="overlap"
                eventPropGetter={(event, start, end, isSelected) => {
                    const props = {
                        style: {}
                    };
                    if (event.type == 'appt') {
                        props.className = 'appt';
                        if (event.nonBookingApptType) {
                            props.style.backgroundColor = event.nonBookingApptType.backColour;
                            props.style.color = event.nonBookingApptType.textColour;
                        } else {
                            const sweep = sweepsByID[`${event.resourceId}`];
                            if (sweep) {
                                if (sweep.diaryBackColour) {
                                    props.style.backgroundColor = sweep.diaryBackColour;
                                }
                                if (sweep.diaryTextColour) {
                                    props.style.color = sweep.diaryTextColour;
                                }
                            }
                        }
                    }
                    return props;
                }}
                //formats={{
                //    dateFormat: 'dd',
                //    dayFormat: (date, culture, localizer) => moment(date).format('DDD'),
                //    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
                //        moment(start).format('DD/MM/YYYY') + ' — ' +
                //        moment(end).format('DD/MM/YYYY')
                //}}
                components={{
                    toolbar: () => null,
                    resourceHeader: (info) => {
                        const sweep = sweepsByID[`${info.resource.resourceId}`];
                        const style = {
                            //borderBottom: '5px solid transparent'
                        };
                        if (sweep && sweep.diaryBackColour) {
                            //style.borderBottomColor = sweep.diaryBackColour;
                        }

                        // Get total travel time for this sweep
                        const { travelStatsBySweep } = this.state;
                        const sweepUserID = parseInt(info.resource.resourceId);
                        let travelTime = 0;
                        if (travelStatsBySweep) {
                            const travelStat = travelStatsBySweep[sweepUserID];
                            if (travelStat) {
                                travelTime = travelStat.travelTime;
                            }
                        }

                        return (
                            <div className="rbc-resource-header" style={style}>
                                <span className="sweep-name">
                                    {info.label}

                                    {!this.state.sweepUserID && allSweeps.length > 1 &&
                                        <button type="button" className="btn btn-tertiary" title="View this sweep only" onClick={() => this.setSweep(info.resource.resourceId)}>
                                            <span className="fa-solid fa-eye" />
                                        </button>
                                    }

                                    <button type="button" className="btn btn-tertiary" title="Print Day Sheet for this sweep" onClick={() => this.printDaySheet(info.resource.resourceId)}>
                                        {this.state.isPrintingDaySheet[parseInt(info.resource.resourceId)] ?
                                            <Loader isInline /> :
                                            <span className="fa-solid fa-print" />
                                        }
                                    </button>

                                </span>
                                {travelTime > 0 &&
                                    <span className="sweep-travel-time" title={`Total travel time: ${travelTime}`}>
                                        <span className="fa-solid fa-van-shuttle" />{' '}
                                        {travelTime}
                                    </span>
                                }
                            </div>
                        )
                    },
                    event: (info) => {
                        const { apptID, nonBookingApptType, customer, type, notes, recurringApptID } = info.event;
                        if (!apptID) {
                            return null;
                        }
                        if (type == 'appt') {
                            const { travelStatsByAppt } = this.state;
                            let travelTime = 0;
                            if (travelStatsByAppt) {
                                const travelStat = travelStatsByAppt[info.event.apptID];
                                if (travelStat) {
                                    travelTime = travelStat.travelTime;
                                }
                            }

                            const travelTimeColour = ReferenceHelpers.getTravelTimeColour(travelTime, loginInfo.account);
                            return (<>
                                {nonBookingApptType && 
                                    <div className="rbc-non-booking-appt">
                                        {notes &&
                                            <span className="icon fa-solid fa-comment" title={notes} />
                                        }
                                        {!!recurringApptID &&
                                            <span className="icon fa-solid fa-arrows-rotate" title="Recurring appointment" />
                                        }
                                        {nonBookingApptType.name}
                                    </div>
                                }
                                {customer &&
                                    <div className="rbc-customer-property">
                                        {this.renderApptInfo(info.event)}
                                    </div>
                                }
                                {travelTime > 0 &&
                                    <div className="rbc-travel-time" style={{ color: travelTimeColour }} title={`Travel time: ${travelTime} minutes`}>
                                        <span className="fa-solid fa-van-shuttle" />{' '}
                                        {travelTime}
                                    </div>
                                }
                            </>);
                        }
                        else if (type == 'travel-time') {
                            return (<>Travel time ({info.event.travelTime}min)</>);
                        }
                    }
                }}
            />

        );
    }

    renderApptInfo(evt) {
        const { loginInfo } = this.props;
        const { customer, property } = evt;
        const infoParts = [];
        loginInfo.account.daySheetApptInfos.forEach(dsai => {
            switch (dsai) {
                case 'CustomerFullName':
                    infoParts.push(TextHelpers.formatName(customer.title, customer.firstName, customer.lastName));
                    break;
                case 'CustomerFullNameOrCompany':
                    infoParts.push(TextHelpers.formatName(customer.title, customer.firstName, customer.lastName) || customer.companyName);
                    break;
                case 'CustomerLastName':
                    infoParts.push(customer.lastName);
                    break;
                case 'CustomerLastNameOrCompany':
                    infoParts.push(customer.lastName || customer.companyName);
                    break;
                case 'CustomerCompany':
                    infoParts.push(customer.companyName);
                    break;
                case 'PropertyLine1':
                    if (property) {
                        infoParts.push(property.address.line1);
                    }
                    break;
                case 'PropertyLine2':
                    if (property) {
                        infoParts.push(property.address.line2);
                    }
                    break;
                case 'PropertyTown':
                    if (property) {
                        infoParts.push(property.address.town);
                    }
                    break;
                case 'PropertyPostcode':
                    if (property) {
                        infoParts.push(property.address.postcode);
                    }
                    break;
                case 'ApptSummary':
                    infoParts.push(TextHelpers.getApptSummary(evt));
                    break;
                case 'ApptTime':
                    infoParts.push(evt.time);
                    break;
                case 'SweepNickname':
                    if (evt.sweep) {
                        infoParts.push(evt.sweep.nickname);
                    }
                    break;
            }
        });

        return infoParts.filter(ip => !!ip).join(', ');
    }

    renderMap() {
        const { loginInfo } = this.props;
        const { appts, appt, sweeps, travelStats, startDate, view, isRescheduling, hideMapLines, areApptsLoading } = this.state;
        return (
            <ErrorBoundary>
                <Map
                    date={startDate}
                    areApptsLoading={areApptsLoading}
                    loginInfo={loginInfo}
                    travelStats={travelStats}
                    isRescheduling={isRescheduling}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBU1Tuo1Dm76DqowfMoOiKpvoT8nthjMcA&libraries=&v=weekly"
                    loadingElement={<div style={{ height: '100%' }} />}
                    containerElement={<React.Fragment />}
                    mapElement={<div className="map" />}
                    appts={appts}
                    activeAppt={appt}
                    hideLines={hideMapLines}
                    onClick={apptID => {
                        if (!isRescheduling) {
                            this.loadAppt(apptID);
                        }
                    }}
                    sweeps={sweeps}
                    view={view}
                />
            </ErrorBoundary>
        );
    }

    renderCurrentAppt() {
        const { loginInfo } = this.props;
        const { apptID, date, time, customerID, propertyID, rebookID, sweepUserID, duration, warnTravelTime } = this.state;

        return (<>

            {loginInfo.accounts.length > 1 &&
                <div className="current-account-name">
                    {loginInfo.account.accountName}
                </div>
            }

            <div className="current-appt">

                <EditAppt
                    id={apptID}
                    date={date}
                    time={time}
                    rebookID={rebookID}
                    propertyID={propertyID}
                    customerID={customerID}
                    sweepUserID={sweepUserID}
                    duration={duration}
                    onApptUpdate={appt => this.updateAppt(appt)}
                    onSetDate={date => this.ensureDateInView(date)}
                    onClose={(reload) => this.cancelEditingAppt(reload)}
                    onSave={(appt, rebooks) => this.onSave(appt, rebooks)}
                    onReschedule={() => this.reschedule()}
                    hasControlPanel
                    warnTravelTime={warnTravelTime}
                    {...this.props}
                />

            </div>

        </>);
    }

    renderOptionsDropdown() {
        const {
            hideMapLines,
            rememberDate,
            hideMap,
            verticalMap
        } = this.state;

        return (
            <div className="btn-group dropdown me-2">
                <button type="button" className="btn btn-tertiary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                    <span className="fa fa-gear" />{' '}
                </button>
                <ul className="dropdown-menu">
                    <li>
                        <a className="dropdown-item" href="#" onClick={e => {
                            e.preventDefault();
                            this.setHideMap(!hideMap);
                        }}>
                            <div className="form-check">
                                <input
                                    id="hideMap"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={!hideMap || ''}
                                    onChange={() => null}
                                />
                                <label className="form-check-label" htmlFor="hideMap">
                                    Show map
                                </label>
                            </div>
                        </a>
                    </li>

                    {!hideMap && <>
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.setHideMapLines(!hideMapLines);
                            }}>
                                <div className="form-check">
                                    <input
                                        id="hideMapLines"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={!hideMapLines || ''}
                                        onChange={() => null}
                                    />
                                    <label className="form-check-label" htmlFor="hideMapLines">
                                        Route lines
                                    </label>
                                </div>
                            </a>
                        </li>
                        <li>
                            <a className="dropdown-item" href="#" onClick={e => {
                                e.preventDefault();
                                this.setVerticalMap(!verticalMap);
                            }}>
                                <div className="form-check">
                                    <input
                                        id="verticalMap"
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={verticalMap || ''}
                                        onChange={() => null}
                                    />
                                    <label className="form-check-label" htmlFor="verticalMap">
                                        Split screen vertically
                                    </label>
                                </div>
                            </a>
                        </li>
                    </>}

                    <li>
                        <a className="dropdown-item" href="#" onClick={e => {
                            e.preventDefault();
                            this.setRememberDate(!rememberDate);
                        }}>
                            <div className="form-check">
                                <input
                                    id="rememberDate"
                                    className="form-check-input"
                                    type="checkbox"
                                    checked={rememberDate || ''}
                                    onChange={e => this.setRememberDate(e.target.checked)}
                                    onChange={() => null}
                                />
                                <label className="form-check-label" htmlFor="rememberDate">
                                    Remember date
                                </label>
                            </div>
                        </a>
                    </li>

                </ul>
            </div>
        );
    }
}

export default DaySheet;