import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ExpandingTextArea from 'react-expanding-textarea';

//-----------------------------------------------------------------

class EditChimneyNotesModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            notes: null
        };
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isChanged: false,
                notes: opt.notes
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async saveAndClose() {
        this.close();
        this.resolve(this.state.notes);
    }

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-chimney-notes-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Save Changes
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderInner() {
        const { notes } = this.state;
        
        return (<>

            <ExpandingTextArea
                className="form-control"
                autoFocus
                value={notes || ''}
                onChange={e => {
                    const notes = e.target.value;
                    this.setState({
                        notes,
                        isChanged: (notes != this.state.notes)
                    })
                }}
                rows={3}
                onFocus={e => e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)}
            />

        </>);
    }
}

export default EditChimneyNotesModal;