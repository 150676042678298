import React from 'react';
import Modal from 'react-bootstrap/Modal';

// Services & Helpers
import FormHelper from 'helpers/FormHelper';
import API from 'API';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class EditNonBookingApptTypeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        };

        this.form = new FormHelper({
            fields: {
                name: {
                    label: 'Name',
                    type: 'text'
                },
                backColour: {
                    label: 'Background colour',
                    type: 'color'
                },
                textColour: {
                    label: 'Text colour',
                    type: 'color'
                },
                location: {
                    label: 'Location for route planning',
                    type: 'single-select',
                    getOptions: () => [
                        {  value: '', text: 'Sweep\'s current location' },
                        { value: 'Home', text: 'Sweep\'s home address' }
                    ]
                }
            },
            getValue: (fieldName) => this.getFieldValue(fieldName),
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            this.setState({
                isOpen: true,
                isLoading: true,
                id: null,
                ...opt
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const {
            id
        } = this.state;

        // Load
        let nonBookingApptType;
        if (id) {
            nonBookingApptType = await API.call('non-booking-appt-type/get/' + id);
        } else {
            nonBookingApptType = {
                backColour: '#F0AAF0',
                textColour: '#000000'
            };
        }

        // Update
        this.setState({
            nonBookingApptType,
            isLoading: false
        });
    }
    
    getFieldValue(fieldName) {
        return this.state.nonBookingApptType[fieldName];
    }

    updateFields(fields, callback) {
        const nonBookingApptType = { ...this.state.nonBookingApptType };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            nonBookingApptType[fieldName] = value;
        }
        this.setState({ nonBookingApptType }, callback);
    }

    async saveAndClose() {
        this.setState({ isLoading: true });
        await API.call('non-booking-appt-type/save', this.state.nonBookingApptType);
        this.close();
        this.resolve();
    }

    async confirmDelete() {
        const confirm = window.confirm('Are you sure you want to delete this non-booking appointment type?');
        if (confirm) {
            this.setState({ isLoading: true });
            await API.call('non-booking-appt-type/delete/' + this.state.id);
            this.close();
            this.resolve();
        }
    }

    render() {
        const { 
            isOpen,
            isLoading,
            id
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-non-booking-appt-type-modal" backdrop="static" keyboard={false}>
                {isLoading ?
                    <Modal.Body><Loader /></Modal.Body> :
                    <form onSubmit={e => { this.saveAndClose(); e.preventDefault() }}>
                        {/*
                        <Modal.Header closeButton>
                            <Modal.Title></Modal.Title>
                        </Modal.Header>
                        */}
                        <Modal.Body>

                            {this.renderInner()}

                        </Modal.Body>
                        <Modal.Footer>
                            <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                                Cancel
                            </button>
                            {!!id &&
                                <button type="button" className="btn btn-secondary" onClick={() => this.confirmDelete()}>
                                    Delete
                                </button>
                            }
                            <button type="submit" className="btn btn-primary ms-auto">
                                Save Changes
                            </button>
                        </Modal.Footer>
                    </form>
                }
            </Modal>
        );
    }

    renderInner() {
        return (
            <fieldset>
                {this.form.render([
                    'name',
                    'backColour',
                    'textColour',
                    'location'
                ])}
            </fieldset>
        );
    }

}

export default EditNonBookingApptTypeModal;