import React from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// Services & Helpers
import API from 'API';
import FormHelper from 'helpers/FormHelper';
import ReferenceHelpers from 'helpers/ReferenceHelpers';

// Components
import Loader from 'components/common/Loader';

//-----------------------------------------------------------------

class CustomerCommunicationModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            setNoneAsDefaultRebook: false,
            rebooks: []
        };

        this.form = new FormHelper({
            fields: {
                date: {
                    label: 'Date',
                    type: 'date'
                },
                time: {
                    label: 'Time',
                    type: 'time'
                },
                type: {
                    label: 'Communication type',
                    type: 'single-select',
                    getOptions: () => ReferenceHelpers.CustomerCommTypes.map(cct => ({
                        value: cct.id,
                        text: cct.name
                    }))
                },
                result: {
                    label: 'Result',
                    type: 'single-select',
                    getOptions: () => {
                        if (this.state.customerComm.useOldResultSystem) {
                            return ReferenceHelpers.OldCustomerCommResults.map(ccr => ({
                                value: ccr.id,
                                text: ccr.name
                            }))
                        }
                        
                        if (!this.state.customerComm.useOldResultSystem) {
                            return ReferenceHelpers.CustomerCommResults.map(ccr => ({
                                value: ccr.id,
                                text: ccr.name
                            }))
                        }
                    }
                },
                rebookID: {
                   label: 'Rebook',
                    type: 'single-select',
                    blankText: 'None',
                    getOptions: () => {
                        return this.state.rebooks.map(cr => {
                            let rebookDate = moment(cr.date).format('MMMM YYYY') + (cr.isClosed ? ' (closed)' : ' (open)')
                           
                            return {
                                value: cr.id,
                                text: rebookDate
                            };
                        })
                    } 
                },
                description: {
                    label: 'Notes',
                    type: 'expanding-text',
                    rows: 2,
                    autoFocus: true
                }
            },
            getValue: (fieldName) => this.state.customerComm[fieldName],
            setValue: (fieldName, value) => this.updateFields({ [fieldName]: value })
        });
    }

    open(opt) {
        return new Promise((resolve) => {
            this.resolve = resolve;
            

            this.setState({
                isOpen: true,
                isChanged: false,
                isLoading: true,
                id: opt.id,
                customerID: opt.customerID,
                propertyID: opt.propertyID,
                rebookID: opt.rebookID,
                setNoneAsDefaultRebook: opt.setNoneAsDefaultRebook,
                type: opt.type
            }, () => {
                this.load();
            });
        });
    }

    close() {
        this.setState({ isOpen: false });
    }

    async load() {
        const { id, customerID, propertyID, rebookID, type, setNoneAsDefaultRebook } = this.state;
        this.setState({ isLoading: true });

        // Load or create customer comm
        let customerComm;
        if (id) {
            customerComm = await API.call('customer-comm/get/' + id);
        } else {
            customerComm = {
                rebookID,
                customerID,
                propertyID,
                date: moment().startOf('day').toDate(),
                time: moment().format('HH:mm'),
                type: (type || 'Call'),
                result: ReferenceHelpers.CustomerCommResults[0].id,
                useOldResultSystem: false
            };
        }

        let rebooks = await this.loadCustomerRebooks(customerComm, customerID, propertyID, rebookID);

        if (setNoneAsDefaultRebook) {
            customerComm.rebookID = null;
        }

        this.setState({
            rebooks
        }, () => {
            this.setState({ customerComm, isLoading: false })
        });
    }
    
    async loadCustomerRebooks(customerComm, customerID, propertyID, rebookID) {
        // Load next rebook for the property
        if (!customerComm.rebook)
        {
            if (rebookID) {
                customerComm.rebook = await API.call('rebook/get/' + rebookID);
            }
            else if (customerComm.propertyID) {
                customerComm.rebook = await API.call('rebook/get-next-for-property/' + customerComm.propertyID);
            }
        }

        // Load list
        const { rebooks } = await API.call('rebook/get-for-customer', {
            propertyID,
            customerID
        });
        if (!customerComm.useOldResultSystem && rebooks.length > 0) {
            customerComm.rebookID = rebooks[0].id;
        }
        
        return rebooks;
    }

    save(customerComm) {
        return new Promise(async (resolve, reject) => {
            this.setState({ isLoading: true });
            await API.call('customer-comm/save', customerComm);
            this.setState({ isLoading: false });
            resolve();
        });
    }

    updateFields(fields) {
        const customerComm = { ...this.state.customerComm };
        for (let fieldName in fields) {
            const value = fields[fieldName];
            customerComm[fieldName] = value;
        }
        this.setState({
            customerComm
        });
    }

    async saveAndClose() {
        const { customerComm } = this.state;
        
        if (customerComm.useOldResultSystem && (this.state.id || customerComm.type != 'Note' || customerComm.description)) {
            // Offer to close the rebook
            if (customerComm.rebook && customerComm.type != 'Note') {

                const date = new Date(customerComm.rebook.date);
                const monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"
                ];

                const formattedDate = monthNames[date.getMonth()] + " " + date.getFullYear();
                const confirm = window.confirm('Do you want to close the rebook for ' + formattedDate + '?');

                if (confirm) {
                    customerComm.closeRebook = true;
                }
            }

            await this.save(customerComm);

            this.close();
            this.resolve();
            return;
        }
        
        if (!customerComm.rebookID) {
            await this.save(customerComm);
            this.close();
            this.resolve();
            return;
        }
        
        if (customerComm.type === 'Note') {
            await this.save(customerComm);
            this.close();
            this.resolve();
            return;
        }
        
        let selectedRebook = this.state.rebooks.find(r => r.id == customerComm.rebookID);
        
        if (selectedRebook === undefined) {
            customerComm.rebookID = null;
            
            await this.save(customerComm);
            this.close();
            this.resolve();
            return;
        }
        
        let selectedRebookFriendlyDate = moment(selectedRebook.date).format('MMMM YYYY');
        
        switch (customerComm.result) {
            case 'AppointmentBooked':
                const appointmentBookedConfirmation = window.confirm('This will close rebook for ' + selectedRebookFriendlyDate);
                
                if (appointmentBookedConfirmation) {
                    await this.save(customerComm);

                    this.close();
                    this.resolve();
                }
                
                break;
            case 'NoChimneyAtProperty':
                let lastRebook = this.state.rebooks.findLast(r => r.date);
                let lastRebookFriendlyDate = moment(lastRebook.date).format('MMMM YYYY')
                
                const noChimneyAtPropertyConfirmation = window.confirm('This will close rebooks from ' + selectedRebookFriendlyDate + ' to ' + lastRebookFriendlyDate);
                
                if (noChimneyAtPropertyConfirmation) {
                    await this.save(customerComm);

                    this.close();
                    this.resolve();
                }
                break;
            case 'NoSweepRequiredThisYear':
                const noSweepRequiredThisYearConfirmation = window.confirm('This will close rebook for ' + selectedRebookFriendlyDate + ' and open a new one for the next year');
                
                if (noSweepRequiredThisYearConfirmation) {
                    await this.save(customerComm);

                    this.close();
                    this.resolve();
                }
                
                break;
            case 'CalledNoAnswer':
            case 'CalledLeftVoiceMail':
            case 'EmailSent':
            case 'CardSent':
            default:
                await this.save(customerComm);
                this.close();
                this.resolve();
                break;
        }
    }

    render() {
        const { 
            isOpen
        } = this.state;

        if (!isOpen) {
            return null;
        }

        return (
            <Modal show onHide={() => this.close()} className="edit-customer-comm-modal" backdrop="static" keyboard={false}>
                <form onSubmit={e => { e.stopPropagation(); e.preventDefault(); this.saveAndClose() }}>
                    <Modal.Body>

                        {this.renderInner()}

                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-secondary" onClick={() => this.close()}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary ms-auto">
                            Save & Close
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        );
    }

    renderOldResultSystem() {
        const { customerComm } = this.state;
        
        return (
            <>
            {this.form.render([
                    'type',
                    (customerComm.type == 'Call' && 'result'),
                    'description',
                ])}
            </>
        )
    }
    
    useNewResultSystem() {
        const { customerComm, rebooks } = this.state;
        
        return (<>
            {rebooks.length > 0 &&
                <div className="row">
                    <div className="col-md-6">
                        {this.form.render(['type'])}
                    </div>
                    <div className="col-md-6">
                        {this.form.render(['rebookID'])}
                    </div>
                </div>    
            }

            {rebooks.length === 0 && 
                <div className="row">
                    {this.form.render(['type'])}    
                </div>
            }

            {this.form.render([(customerComm.type === 'Call' && 'result')])}

            {this.form.render(['description'])}

        </>)
    }
    
    
    renderInner() {
        const { isLoading, customerComm } = this.state;

        if (isLoading) {
            return (<Loader />);
        }
        
        return (<>

            <div className="row">

                <div className="col-md-6">

                    {this.form.render('date')}

                </div>

                <div className="col-md-6">

                    {this.form.render('time')}

                </div>

            </div>

            {this.state.customerComm.useOldResultSystem ? (
                <>
                    {this.renderOldResultSystem()}
                </>
            ) : 
                <>
                    {this.useNewResultSystem()}
                </>}
        </>);
    }
}

export default CustomerCommunicationModal;